const styles: { [key: string]: React.CSSProperties } = {
  allTasksHeader: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#000000",
  },
  tabsHeader: {
    borderTop: "1px solid #E8E8E8",
    marginTop: "8px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "HelveticaNeue",
  },
  title: {
    fontWeight: "bold",
    color: "#667580",
    fontSize: "0.75rem",
    margin: 0,
    maxWidth: "5rem",
  },
  content: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: "#000000",
    margin: 0,
  },
};

export default styles;
