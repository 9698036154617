import React from "react";
import { Card } from "@wellth/web-ui";
import moment from "moment-timezone";
import COLORS from "utils/colors";
import { Timeline } from "antd";
import { DATE_TIME_FORMAT_YEAR_APOSTROPHE } from "utils/date";
import { ProspectStatus as ProspectStatusType } from "types/globalTypes";
import { ProspectStatus } from "components/ProspectPage";
import { capitalizeFirstLetter } from "utils/string";
import CARD_STYLES from "../styles";

export interface ProspectStatusHistoryProps {
  prospectStatuses: Partial<ProspectStatusType[]>;
}

type ProspectStatusHistoryType = Partial<ProspectStatusType> & {
  previousStatus: ProspectStatus;
};

const BODY_STYLE: React.CSSProperties = {
  maxHeight: "61.25rem",
  display: "block",
  overflow: "auto",
  padding: 0,
};

const HEADER_STYLE: React.CSSProperties = {
  paddingLeft: "0.5rem",
};

const TIMELINE_STYLE: React.CSSProperties = {
  marginTop: "2rem",
};

const TIMELINE_CONTAINER_STYLE: React.CSSProperties = {
  border: `1px solid ${COLORS.gray}`,
  marginRight: "0.5rem",
  borderRadius: "0.25rem",
  padding: "0.5rem",
};

const HEADER_TEXT_STYLE: React.CSSProperties = {
  fontSize: ".9rem",
  fontWeight: "bold",
};

const ADMIN_TEXT_STYLE: React.CSSProperties = {
  fontWeight: "lighter",
};

const setHeaderText = (
  currentValue: ProspectStatus,
  previousValue: ProspectStatus,
  adminFirstName: string,
  adminLastName: string,
) => {
  let fontColor;
  switch (currentValue) {
    case ProspectStatus.open:
      fontColor = COLORS.green;
      break;
    case ProspectStatus.closed:
      fontColor = COLORS.red;
      break;
    default:
      fontColor = COLORS.black;
      break;
  }

  const admin =
    adminFirstName === "Wellth"
      ? "System"
      : `${adminFirstName} ${adminLastName}`;
  return (
    <div style={HEADER_TEXT_STYLE}>
      Status Changed{" "}
      {previousValue && (
        <span>from {capitalizeFirstLetter(previousValue)}</span>
      )}{" "}
      to:{" "}
      <span style={{ color: fontColor }}>
        {capitalizeFirstLetter(currentValue)}
      </span>
      <span style={ADMIN_TEXT_STYLE}>{` - by ${admin}`}</span>
    </div>
  );
};

const createProspectStatusHistory = (
  statusArray: ProspectStatusType[] = [],
): ProspectStatusHistoryType[] =>
  (statusArray || []).reduce((history, currentStatus, index) => {
    const previousStatus = statusArray[index + 1]
      ? statusArray[index + 1].value
      : null;
    history.push({ previousStatus, ...currentStatus });

    return history;
  }, []) as ProspectStatusHistoryType[];

export const ProspectStatusHistory: React.FC<ProspectStatusHistoryProps> = ({
  prospectStatuses,
}) => (
  <Card
    title="Prospect Status History"
    style={CARD_STYLES.card}
    headStyle={HEADER_STYLE}
    bodyStyle={BODY_STYLE}
  >
    <Timeline style={TIMELINE_STYLE}>
      {createProspectStatusHistory(prospectStatuses).map((status) => (
        <Timeline.Item key={status.id} color={COLORS.gray}>
          <div>
            {moment(status.statusTransitionDate)
              .tz("America/Los_Angeles")
              .format(DATE_TIME_FORMAT_YEAR_APOSTROPHE)}
            <div style={TIMELINE_CONTAINER_STYLE}>
              {setHeaderText(
                status.value as ProspectStatus,
                status.previousStatus,
                status.adminFirstName,
                status.adminLastName,
              )}
              <div>
                <b>Reason:</b> {status.reason}
              </div>
              {status.reasonDetail && (
                <div>
                  <b>Reason Detail:</b> {status.reasonDetail}
                </div>
              )}
              {status.note && (
                <div>
                  <b>Note:</b> {status.note}
                </div>
              )}
            </div>
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  </Card>
);
