export const CREDIT_APPOINTMENT_REASONS = [
  { title: "App Issue / Bug", value: "App Issue / Bug" },
  {
    title: "Connection / Internet Issue",
    value: "Connection / Internet Issue",
  },
  { title: "Phone Issue", value: "Phone Issue" },
  {
    title: "Insufficient supplies / needs refill",
    value: "Insufficient supplies / needs refill",
  },
  { title: "Travel or moving", value: "Travel or moving" },
  { title: "Personal Emergency", value: "Personal Emergency" },
  {
    title: "Admitted into a Facility / Hospital",
    value: "Admitted into a Facility / Hospital",
  },
  { title: "Illness", value: "Illness" },
  {
    title: "Education / Misunderstanding",
    value: "Education / Misunderstanding",
  },
  { title: "Regimen has changed", value: "Regimen has changed" },
  { title: "Member Expired", value: "Member Expired" },
  { title: "Other Technical Issue(s)", value: "Other Technical Issue(s)" },
  { title: "Work-Related Issue", value: "Work-Related Issue" },
  { title: "Doctors Appointment", value: "Doctors Appointment" },
];

// REASON OPTIONS
const TECHNICAL_BARRIER = "Technical Barrier";
const MEDICAL_CARE_PLAN_BARRIER = "Medical / Care Plan Barrier";
const WELLTH_TRAINING_BARRIER = "Wellth Training Barrier";
const PERSONAL_BARRIER = "Personal Barrier";
const PERSONAL_CHOICE = "Personal Choice - No Clear Barrier";
export const TO_BE_ACTIVATED = "Workflow: To Be Activated";
export const OTHER = "Other";

// REASON DETAIL OPTIONS
const APP_ISSUE = "App Issue / Bug";
const ONE_TIME_CONNECTION = "One time connection / Internet Issue";
const DOES_NOT_HAVE_REGULAR_ACCESS =
  "Does not have regular access to Internet or data";
const PHONE_ISSUE = "Phone issue";
const INSUFFICIENT_SUPPLIES = "Insufficient Supplies / needs refill";
const ADMITTED_INTO_FACILITY = "Admitted into facility / hospital";
const DOCTORS_APPT = "Doctors appointment / tests";
const GOT_SICK = "Got sick";
const CHRONIC_ILLNESS = "Chronic illness";
const HEALTH_REGIMEN_CHANGE = "Health regimen change";
const RECOVERY = "Recovery";
const PROCEDURE = "Has / Had a procedure";
const CONFUSED_WINDOWS = "Confused about check-in windows";
const CONFUSED_SUBMIT_PHOTO = "Confused about how to submit a photo";
const COULDNT_FIND_APP = "Couldn't find app";
const CONFUSED_MULTIPLE_CHECKINS = "Confused about multiple check-ins per day";
const CONFUSED_PERMISSIONS = "Confused about permissions";
const CONFUSED_REMINDERS = "Confused about reminders";
const ONGOING_DIFFICULTY = "Ongoing difficulty or tragedy";
const ONE_TIME_EMERGENCY = "One time emergency";
const CONSISTENT_SCHEDULE_IRREGULARITY = "Consistent schedule irregularity";
const FORGOT_CALL_SAME_DAY =
  "Forgot and can't remember why (and called the same day)";
const FORGOT_CALL_LATER = "Forgot and can't remember why (and called later)";
const SLEPT_THROUGH = "Slept through their reminder";
const GOT_BUSY = "Got busy / additional responsibilities";
const ONE_TIME_ERRAND = "One time recreational event or errand";
const CHANGED_PHYSICAL_ENVIRONMENT = "Changed physical environment";

const CREDIT_CHECK_IN_REASONS = [
  { title: TECHNICAL_BARRIER, value: TECHNICAL_BARRIER },
  { title: MEDICAL_CARE_PLAN_BARRIER, value: MEDICAL_CARE_PLAN_BARRIER },
  { title: WELLTH_TRAINING_BARRIER, value: WELLTH_TRAINING_BARRIER },
  { title: PERSONAL_BARRIER, value: PERSONAL_BARRIER },
  { title: PERSONAL_CHOICE, value: PERSONAL_CHOICE },
  { title: TO_BE_ACTIVATED, value: TO_BE_ACTIVATED },
  { title: OTHER, value: OTHER },
];

const TECHNICAL_BARRIER_REASON_DETAILS = [
  { title: APP_ISSUE, value: APP_ISSUE },
  { title: ONE_TIME_CONNECTION, value: ONE_TIME_CONNECTION },
  { title: DOES_NOT_HAVE_REGULAR_ACCESS, value: DOES_NOT_HAVE_REGULAR_ACCESS },
  { title: PHONE_ISSUE, value: PHONE_ISSUE },
];

const MEDICAL_CARE_PLAN_BARRIER_DETAILS = [
  { title: INSUFFICIENT_SUPPLIES, value: INSUFFICIENT_SUPPLIES },
  { title: ADMITTED_INTO_FACILITY, value: ADMITTED_INTO_FACILITY },
  { title: DOCTORS_APPT, value: DOCTORS_APPT },
  { title: GOT_SICK, value: GOT_SICK },
  { title: CHRONIC_ILLNESS, value: CHRONIC_ILLNESS },
  { title: HEALTH_REGIMEN_CHANGE, value: HEALTH_REGIMEN_CHANGE },
  { title: RECOVERY, value: RECOVERY },
  { title: PROCEDURE, value: PROCEDURE },
];

const WELLTH_TRAINING_BARRIER_DETAILS = [
  { title: CONFUSED_WINDOWS, value: CONFUSED_WINDOWS },
  { title: CONFUSED_SUBMIT_PHOTO, value: CONFUSED_SUBMIT_PHOTO },
  { title: COULDNT_FIND_APP, value: COULDNT_FIND_APP },
  { title: CONFUSED_MULTIPLE_CHECKINS, value: CONFUSED_MULTIPLE_CHECKINS },
  { title: CONFUSED_PERMISSIONS, value: CONFUSED_PERMISSIONS },
  { title: CONFUSED_REMINDERS, value: CONFUSED_REMINDERS },
];

const PERSONAL_BARRIER_DETAILS = [
  { title: ONGOING_DIFFICULTY, value: ONGOING_DIFFICULTY },
  { title: ONE_TIME_EMERGENCY, value: ONE_TIME_EMERGENCY },
  {
    title: CONSISTENT_SCHEDULE_IRREGULARITY,
    value: CONSISTENT_SCHEDULE_IRREGULARITY,
  },
];

const PERSONAL_CHOICE_DETAILS = [
  { title: FORGOT_CALL_SAME_DAY, value: FORGOT_CALL_SAME_DAY },
  { title: FORGOT_CALL_LATER, value: FORGOT_CALL_LATER },
  { title: SLEPT_THROUGH, value: SLEPT_THROUGH },
  { title: GOT_BUSY, value: GOT_BUSY },
  { title: ONE_TIME_ERRAND, value: ONE_TIME_ERRAND },
  { title: CHANGED_PHYSICAL_ENVIRONMENT, value: CHANGED_PHYSICAL_ENVIRONMENT },
];

export const REASON_TO_DETAILS_MATCH = {
  [TECHNICAL_BARRIER]: TECHNICAL_BARRIER_REASON_DETAILS,
  [MEDICAL_CARE_PLAN_BARRIER]: MEDICAL_CARE_PLAN_BARRIER_DETAILS,
  [WELLTH_TRAINING_BARRIER]: WELLTH_TRAINING_BARRIER_DETAILS,
  [PERSONAL_BARRIER]: PERSONAL_BARRIER_DETAILS,
  [PERSONAL_CHOICE]: PERSONAL_CHOICE_DETAILS,
  [TO_BE_ACTIVATED]: [],
  [OTHER]: [],
};

export const REASON_OPTIONS = {
  "check-in": CREDIT_CHECK_IN_REASONS,
  appointment: CREDIT_APPOINTMENT_REASONS,
};
