import * as Types from 'types/globalTypes';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
const defaultOptions = {} as const;
export const FullAppointmentLocationFragmentDoc = gql`
    fragment FullAppointmentLocation on AppointmentLocation {
  name
  latitude
  longitude
  address
  phoneNumber
}
    `;
export const FullAppointmentFragmentDoc = gql`
    fragment FullAppointment on Appointment {
  appointmentId: id
  type
  name
  dueTime
  isAllDay
  lossAmount
  lossCurrencySymbol
  location {
    ...FullAppointmentLocation
  }
  isMissed
  status
  allowCredit
}
    ${FullAppointmentLocationFragmentDoc}`;
export const CalendarEventFragmentDoc = gql`
    fragment CalendarEvent on ReminderDay {
  checkInId
  reminderDate
  checkInLocalTime
  reminderIsRequired
  windowAfterMinutes
  windowBeforeMinutes
  checkInVerificationStatus
  checkInIsValid
  reminderWindowHasClosed
  photoUrl
  reminderTime
  frequency
  checkInCategory
  checkInStatus
  reminderId
  reminderDate
  checkInTimezone
  isDemo
  calendarDate
}
    `;
export const FullReminderFragmentDoc = gql`
    fragment FullReminder on Reminder {
  nodeId
  id
  checkInCategory
  createdAt
  updatedAt
  startDate
  endDate
  monTime
  tueTime
  wedTime
  thuTime
  friTime
  satTime
  sunTime
  windowBeforeMinutes
  windowAfterMinutes
  isRequired
  isDemo
  linkedFutureReminderId
  interval
  intervalCount
}
    `;
export const FullReminderConfigFragmentDoc = gql`
    fragment FullReminderConfig on ReminderConfig {
  id
  monTime
  startDayIndex
  lengthInDays
  checkInCategory
  windowBeforeMinutes
  windowAfterMinutes
  isRequired
  isDemo
  interval
}
    `;
export const FullRewardPeriodConfigFragmentDoc = gql`
    fragment FullRewardPeriodConfig on RewardPeriodConfig {
  id
  rewardAmount
  rewardCurrency
  lengthInDays
  lossPerDayMax
  lossType
  programId
  startDayIndex
}
    `;
export const MealCheckInFragmentDoc = gql`
    fragment MealCheckIn on MealCheckIn {
  nodeId
  id
  createdAt
  updatedAt
  patientId
  photoURL: photoUrl
  reminderId
  verificationStatus
  verifierId
  category
  items
  checkInTimestamp
  checkInTimezone
}
    `;
export const MeasurementCheckInFragmentDoc = gql`
    fragment MeasurementCheckIn on MeasurementCheckIn {
  nodeId
  id
  createdAt
  updatedAt
  patientId
  photoURL: photoUrl
  reminderId
  verificationStatus
  verifierId
  category
  checkInTimestamp
  checkInTimezone
  measurements {
    name
    unit
    autoValue
    manualValue
    value
  }
}
    `;
export const MedicationCheckInFragmentDoc = gql`
    fragment MedicationCheckIn on MedicationCheckIn {
  nodeId
  id
  createdAt
  updatedAt
  patientId
  photoURL: photoUrl
  reminderId
  verificationStatus
  verifierId
  category
  checkInTimestamp
  checkInTimezone
  autoHasMeds
  autoMedCount
  manualHasMeds
  manualMedCount
}
    `;
export const MemberCustomerIdentityFragmentDoc = gql`
    fragment MemberCustomerIdentity on Member {
  id
  customerId
  personId
}
    `;
export const MemberProfileAdherenceFragmentDoc = gql`
    fragment MemberProfileAdherence on AdherenceSummary {
  requiredReminderCount
  totalReminderCount
  requiredValidCheckInCount
  totalValidCheckInCount
  percentRequiredRemindersAdherent
  percentTotalRemindersAdherent
  creditedCheckInCount
  percentTotalRemindersNonCreditedAdherent
}
    `;
export const PersonDemographicsFragmentDoc = gql`
    fragment PersonDemographics on Person {
  id
  primaryLanguage
  primaryTimezone
  birthDate
  gender
  ethnicity
  race
  medicareId
  coverageType
  planName
}
    `;
export const FullMailingAddressFragmentDoc = gql`
    fragment FullMailingAddress on Address {
  id
  street
  street2
  city
  state
  zip
  country
  isPrimary
}
    `;
export const FullNameFragmentDoc = gql`
    fragment FullName on Name {
  id
  firstName
  middleName
  lastName
  isPrimary
}
    `;
export const FullPhoneNumberFragmentDoc = gql`
    fragment FullPhoneNumber on PhoneNumber {
  id
  phoneNumber
  type
  isPrimary
}
    `;
export const EmailFragmentDoc = gql`
    fragment Email on EmailAddress {
  id
  emailAddress
  isPrimary
}
    `;
export const PersonProfileInformationFragmentDoc = gql`
    fragment PersonProfileInformation on Person {
  ...PersonDemographics
  addresses {
    ...FullMailingAddress
  }
  names {
    ...FullName
  }
  phoneNumbers {
    ...FullPhoneNumber
  }
  emailAddresses {
    ...Email
  }
}
    ${PersonDemographicsFragmentDoc}
${FullMailingAddressFragmentDoc}
${FullNameFragmentDoc}
${FullPhoneNumberFragmentDoc}
${EmailFragmentDoc}`;
export const MemberProfileInformationFragmentDoc = gql`
    fragment MemberProfileInformation on Member {
  id
  siteName
  externalId
  medicaidId
  isTestMember
  isAutoPauseEnabled
  eligibilityEndDate
  wellthId
  latestTimezone {
    timezoneName
  }
  isReminderMessagingEnabled
  person {
    ...PersonProfileInformation
  }
  program {
    programTemplate
  }
}
    ${PersonProfileInformationFragmentDoc}`;
export const FullPaymentOptionFragmentDoc = gql`
    fragment FullPaymentOption on PaymentOption {
  id
  name
  processor
  packId
  default
}
    `;
export const FullPersonSearchResultFragmentDoc = gql`
    fragment FullPersonSearchResult on IndividualSearchResult {
  id
  shortId
  individualType
  createdAt
  updatedAt
  personId
  birthDate
  externalId
  names {
    firstName
    lastName
  }
  phoneNumbers {
    id
    isPrimary
    phoneNumber
  }
  location
  programCode
  status
}
    `;
export const FullProgramFragmentDoc = gql`
    fragment FullProgram on Program {
  id
  adminId
  programCode
  name
  expiry
  useCount
  maximumUses
  customerId
  isTestProgram
  sector
  note
  pauseAfterNoActivityDays
  inactivateAfterPausedDays
  rewardCardInfoUrl
  programTemplate
  customerByCustomerId {
    name
  }
}
    `;
export const AddIdentityTagDocument = gql`
    mutation addIdentityTag($input: AddIdentityTagInput!) {
  addIdentityTag(input: $input) {
    id
  }
}
    `;

/**
 * __useAddIdentityTagMutation__
 *
 * To run a mutation, you first call `useAddIdentityTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIdentityTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIdentityTagMutation, { data, loading, error }] = useAddIdentityTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIdentityTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AddIdentityTagMutation, Types.AddIdentityTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.AddIdentityTagMutation, Types.AddIdentityTagMutationVariables>(AddIdentityTagDocument, options);
      }
export type AddIdentityTagMutationHookResult = ReturnType<typeof useAddIdentityTagMutation>;
export type AddIdentityTagMutationResult = ApolloReactCommon.MutationResult<Types.AddIdentityTagMutation>;
export type AddIdentityTagMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.AddIdentityTagMutation, Types.AddIdentityTagMutationVariables>;
export const ApproveSmsOutreachMessageDocument = gql`
    mutation approveSmsOutreachMessage($messageId: UUID!) {
  approveSmsOutreachMessage(messageId: $messageId)
}
    `;

/**
 * __useApproveSmsOutreachMessageMutation__
 *
 * To run a mutation, you first call `useApproveSmsOutreachMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSmsOutreachMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSmsOutreachMessageMutation, { data, loading, error }] = useApproveSmsOutreachMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useApproveSmsOutreachMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ApproveSmsOutreachMessageMutation, Types.ApproveSmsOutreachMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.ApproveSmsOutreachMessageMutation, Types.ApproveSmsOutreachMessageMutationVariables>(ApproveSmsOutreachMessageDocument, options);
      }
export type ApproveSmsOutreachMessageMutationHookResult = ReturnType<typeof useApproveSmsOutreachMessageMutation>;
export type ApproveSmsOutreachMessageMutationResult = ApolloReactCommon.MutationResult<Types.ApproveSmsOutreachMessageMutation>;
export type ApproveSmsOutreachMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.ApproveSmsOutreachMessageMutation, Types.ApproveSmsOutreachMessageMutationVariables>;
export const CancelPauseRequestDocument = gql`
    mutation cancelPauseRequest($input: CancelPauseRequestInput!) {
  cancelPauseRequest(input: $input) {
    boolean
  }
}
    `;

/**
 * __useCancelPauseRequestMutation__
 *
 * To run a mutation, you first call `useCancelPauseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPauseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPauseRequestMutation, { data, loading, error }] = useCancelPauseRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPauseRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CancelPauseRequestMutation, Types.CancelPauseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CancelPauseRequestMutation, Types.CancelPauseRequestMutationVariables>(CancelPauseRequestDocument, options);
      }
export type CancelPauseRequestMutationHookResult = ReturnType<typeof useCancelPauseRequestMutation>;
export type CancelPauseRequestMutationResult = ApolloReactCommon.MutationResult<Types.CancelPauseRequestMutation>;
export type CancelPauseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CancelPauseRequestMutation, Types.CancelPauseRequestMutationVariables>;
export const CancelUnpauseRequestDocument = gql`
    mutation cancelUnpauseRequest($input: CancelUnpauseRequestInput!) {
  cancelUnpauseRequest(input: $input) {
    boolean
  }
}
    `;

/**
 * __useCancelUnpauseRequestMutation__
 *
 * To run a mutation, you first call `useCancelUnpauseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUnpauseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUnpauseRequestMutation, { data, loading, error }] = useCancelUnpauseRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelUnpauseRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CancelUnpauseRequestMutation, Types.CancelUnpauseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CancelUnpauseRequestMutation, Types.CancelUnpauseRequestMutationVariables>(CancelUnpauseRequestDocument, options);
      }
export type CancelUnpauseRequestMutationHookResult = ReturnType<typeof useCancelUnpauseRequestMutation>;
export type CancelUnpauseRequestMutationResult = ApolloReactCommon.MutationResult<Types.CancelUnpauseRequestMutation>;
export type CancelUnpauseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CancelUnpauseRequestMutation, Types.CancelUnpauseRequestMutationVariables>;
export const ChangeBonusBehaviorSubmissionStatusDocument = gql`
    mutation changeBonusBehaviorSubmissionStatus($input: ChangeBonusBehaviorSubmissionStatusInput!) {
  changeBonusBehaviorSubmissionStatus(input: $input) {
    id
    submissionType
    status
    photoUrl
    bonusBehavior {
      status
    }
  }
}
    `;

/**
 * __useChangeBonusBehaviorSubmissionStatusMutation__
 *
 * To run a mutation, you first call `useChangeBonusBehaviorSubmissionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBonusBehaviorSubmissionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBonusBehaviorSubmissionStatusMutation, { data, loading, error }] = useChangeBonusBehaviorSubmissionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeBonusBehaviorSubmissionStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ChangeBonusBehaviorSubmissionStatusMutation, Types.ChangeBonusBehaviorSubmissionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.ChangeBonusBehaviorSubmissionStatusMutation, Types.ChangeBonusBehaviorSubmissionStatusMutationVariables>(ChangeBonusBehaviorSubmissionStatusDocument, options);
      }
export type ChangeBonusBehaviorSubmissionStatusMutationHookResult = ReturnType<typeof useChangeBonusBehaviorSubmissionStatusMutation>;
export type ChangeBonusBehaviorSubmissionStatusMutationResult = ApolloReactCommon.MutationResult<Types.ChangeBonusBehaviorSubmissionStatusMutation>;
export type ChangeBonusBehaviorSubmissionStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.ChangeBonusBehaviorSubmissionStatusMutation, Types.ChangeBonusBehaviorSubmissionStatusMutationVariables>;
export const ChangeMemberStatusDocument = gql`
    mutation ChangeMemberStatus($id: UUID!, $status: String!, $reason: String, $reasonDetail: String, $note: String) {
  changeMemberStatus(memberId: $id, status: $status, reason: $reason, reasonDetail: $reasonDetail, note: $note)
}
    `;

/**
 * __useChangeMemberStatusMutation__
 *
 * To run a mutation, you first call `useChangeMemberStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMemberStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMemberStatusMutation, { data, loading, error }] = useChangeMemberStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      reason: // value for 'reason'
 *      reasonDetail: // value for 'reasonDetail'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useChangeMemberStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ChangeMemberStatusMutation, Types.ChangeMemberStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.ChangeMemberStatusMutation, Types.ChangeMemberStatusMutationVariables>(ChangeMemberStatusDocument, options);
      }
export type ChangeMemberStatusMutationHookResult = ReturnType<typeof useChangeMemberStatusMutation>;
export type ChangeMemberStatusMutationResult = ApolloReactCommon.MutationResult<Types.ChangeMemberStatusMutation>;
export type ChangeMemberStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.ChangeMemberStatusMutation, Types.ChangeMemberStatusMutationVariables>;
export const ChangeProspectStatusDocument = gql`
    mutation ChangeProspectStatus($id: UUID!, $status: String!, $reason: String, $reasonDetail: String, $note: String) {
  changeProspectStatus(prospectId: $id, status: $status, reason: $reason, reasonDetail: $reasonDetail, note: $note)
}
    `;

/**
 * __useChangeProspectStatusMutation__
 *
 * To run a mutation, you first call `useChangeProspectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProspectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProspectStatusMutation, { data, loading, error }] = useChangeProspectStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      reason: // value for 'reason'
 *      reasonDetail: // value for 'reasonDetail'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useChangeProspectStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ChangeProspectStatusMutation, Types.ChangeProspectStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.ChangeProspectStatusMutation, Types.ChangeProspectStatusMutationVariables>(ChangeProspectStatusDocument, options);
      }
export type ChangeProspectStatusMutationHookResult = ReturnType<typeof useChangeProspectStatusMutation>;
export type ChangeProspectStatusMutationResult = ApolloReactCommon.MutationResult<Types.ChangeProspectStatusMutation>;
export type ChangeProspectStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.ChangeProspectStatusMutation, Types.ChangeProspectStatusMutationVariables>;
export const CreateAccountTransactionCreditDocument = gql`
    mutation CreateAccountTransactionCredit($input: CreateAccountTransactionCreditInput!) {
  createAccountTransactionCredit(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateAccountTransactionCreditMutation__
 *
 * To run a mutation, you first call `useCreateAccountTransactionCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountTransactionCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountTransactionCreditMutation, { data, loading, error }] = useCreateAccountTransactionCreditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountTransactionCreditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateAccountTransactionCreditMutation, Types.CreateAccountTransactionCreditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateAccountTransactionCreditMutation, Types.CreateAccountTransactionCreditMutationVariables>(CreateAccountTransactionCreditDocument, options);
      }
export type CreateAccountTransactionCreditMutationHookResult = ReturnType<typeof useCreateAccountTransactionCreditMutation>;
export type CreateAccountTransactionCreditMutationResult = ApolloReactCommon.MutationResult<Types.CreateAccountTransactionCreditMutation>;
export type CreateAccountTransactionCreditMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateAccountTransactionCreditMutation, Types.CreateAccountTransactionCreditMutationVariables>;
export const CreateBonusBehaviorConfigDocument = gql`
    mutation createBonusBehaviorConfig($input: CreateBonusBehaviorConfigInput!) {
  createBonusBehaviorConfig(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useCreateBonusBehaviorConfigMutation__
 *
 * To run a mutation, you first call `useCreateBonusBehaviorConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBonusBehaviorConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBonusBehaviorConfigMutation, { data, loading, error }] = useCreateBonusBehaviorConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBonusBehaviorConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateBonusBehaviorConfigMutation, Types.CreateBonusBehaviorConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateBonusBehaviorConfigMutation, Types.CreateBonusBehaviorConfigMutationVariables>(CreateBonusBehaviorConfigDocument, options);
      }
export type CreateBonusBehaviorConfigMutationHookResult = ReturnType<typeof useCreateBonusBehaviorConfigMutation>;
export type CreateBonusBehaviorConfigMutationResult = ApolloReactCommon.MutationResult<Types.CreateBonusBehaviorConfigMutation>;
export type CreateBonusBehaviorConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateBonusBehaviorConfigMutation, Types.CreateBonusBehaviorConfigMutationVariables>;
export const CreateBonusBehaviorSubmissionDocument = gql`
    mutation createBonusBehaviorSubmission($input: CreateBonusBehaviorSubmissionInput!) {
  createBonusBehaviorSubmission(input: $input) {
    id
    submissionType
    status
    photoUrl
    bonusBehavior {
      id
      status
      icon
      title
      createdAt
      updatedAt
      startDate
      endDate
      submittedDate
      completedDate
      rewards {
        amount
        currencySymbol
      }
      submissionType
      submissionPage {
        title
        detail
        icon
        getHelpIsEnabled
        getHelpText
      }
      verifyPage {
        title
      }
      successPage {
        title
        detail
        icon
        backgroundColor
      }
      instructionPages {
        title
        detail
        icon
        backgroundColor
        pageIndex
        buttonText
      }
    }
  }
}
    `;

/**
 * __useCreateBonusBehaviorSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateBonusBehaviorSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBonusBehaviorSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBonusBehaviorSubmissionMutation, { data, loading, error }] = useCreateBonusBehaviorSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBonusBehaviorSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateBonusBehaviorSubmissionMutation, Types.CreateBonusBehaviorSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateBonusBehaviorSubmissionMutation, Types.CreateBonusBehaviorSubmissionMutationVariables>(CreateBonusBehaviorSubmissionDocument, options);
      }
export type CreateBonusBehaviorSubmissionMutationHookResult = ReturnType<typeof useCreateBonusBehaviorSubmissionMutation>;
export type CreateBonusBehaviorSubmissionMutationResult = ApolloReactCommon.MutationResult<Types.CreateBonusBehaviorSubmissionMutation>;
export type CreateBonusBehaviorSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateBonusBehaviorSubmissionMutation, Types.CreateBonusBehaviorSubmissionMutationVariables>;
export const CreateEmailAddressDocument = gql`
    mutation CreateEmailAddress($input: CreateEmailAddressInput!, $contactId: UUID!, $contactType: ContactType!) {
  createEmailAddress(input: $input, contactId: $contactId, contactType: $contactType) {
    emailAddress {
      ...Email
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useCreateEmailAddressMutation__
 *
 * To run a mutation, you first call `useCreateEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailAddressMutation, { data, loading, error }] = useCreateEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactId: // value for 'contactId'
 *      contactType: // value for 'contactType'
 *   },
 * });
 */
export function useCreateEmailAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateEmailAddressMutation, Types.CreateEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateEmailAddressMutation, Types.CreateEmailAddressMutationVariables>(CreateEmailAddressDocument, options);
      }
export type CreateEmailAddressMutationHookResult = ReturnType<typeof useCreateEmailAddressMutation>;
export type CreateEmailAddressMutationResult = ApolloReactCommon.MutationResult<Types.CreateEmailAddressMutation>;
export type CreateEmailAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateEmailAddressMutation, Types.CreateEmailAddressMutationVariables>;
export const CreateMailingAddressDocument = gql`
    mutation CreateMailingAddress($input: CreateAddressInput!, $contactId: UUID!, $contactType: ContactType!) {
  createAddress(input: $input, contactId: $contactId, contactType: $contactType) {
    address {
      ...FullMailingAddress
    }
  }
}
    ${FullMailingAddressFragmentDoc}`;

/**
 * __useCreateMailingAddressMutation__
 *
 * To run a mutation, you first call `useCreateMailingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMailingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMailingAddressMutation, { data, loading, error }] = useCreateMailingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactId: // value for 'contactId'
 *      contactType: // value for 'contactType'
 *   },
 * });
 */
export function useCreateMailingAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateMailingAddressMutation, Types.CreateMailingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateMailingAddressMutation, Types.CreateMailingAddressMutationVariables>(CreateMailingAddressDocument, options);
      }
export type CreateMailingAddressMutationHookResult = ReturnType<typeof useCreateMailingAddressMutation>;
export type CreateMailingAddressMutationResult = ApolloReactCommon.MutationResult<Types.CreateMailingAddressMutation>;
export type CreateMailingAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateMailingAddressMutation, Types.CreateMailingAddressMutationVariables>;
export const CreateMealCheckInDocument = gql`
    mutation CreateMealCheckIn($input: CreateMealCheckInInput!) {
  createMealCheckIn(input: $input) {
    mealCheckIn {
      ...MealCheckIn
    }
  }
}
    ${MealCheckInFragmentDoc}`;

/**
 * __useCreateMealCheckInMutation__
 *
 * To run a mutation, you first call `useCreateMealCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMealCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMealCheckInMutation, { data, loading, error }] = useCreateMealCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMealCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateMealCheckInMutation, Types.CreateMealCheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateMealCheckInMutation, Types.CreateMealCheckInMutationVariables>(CreateMealCheckInDocument, options);
      }
export type CreateMealCheckInMutationHookResult = ReturnType<typeof useCreateMealCheckInMutation>;
export type CreateMealCheckInMutationResult = ApolloReactCommon.MutationResult<Types.CreateMealCheckInMutation>;
export type CreateMealCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateMealCheckInMutation, Types.CreateMealCheckInMutationVariables>;
export const CreateMeasurementCheckInDocument = gql`
    mutation CreateMeasurementCheckIn($input: CreateMeasurementCheckInInput!) {
  createMeasurementCheckIn(input: $input) {
    measurementCheckIn {
      ...MeasurementCheckIn
    }
  }
}
    ${MeasurementCheckInFragmentDoc}`;

/**
 * __useCreateMeasurementCheckInMutation__
 *
 * To run a mutation, you first call `useCreateMeasurementCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeasurementCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeasurementCheckInMutation, { data, loading, error }] = useCreateMeasurementCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMeasurementCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateMeasurementCheckInMutation, Types.CreateMeasurementCheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateMeasurementCheckInMutation, Types.CreateMeasurementCheckInMutationVariables>(CreateMeasurementCheckInDocument, options);
      }
export type CreateMeasurementCheckInMutationHookResult = ReturnType<typeof useCreateMeasurementCheckInMutation>;
export type CreateMeasurementCheckInMutationResult = ApolloReactCommon.MutationResult<Types.CreateMeasurementCheckInMutation>;
export type CreateMeasurementCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateMeasurementCheckInMutation, Types.CreateMeasurementCheckInMutationVariables>;
export const CreateMedicationCheckInDocument = gql`
    mutation CreateMedicationCheckIn($input: CreateMedicationCheckInInput!) {
  createMedicationCheckIn(input: $input) {
    medicationCheckIn {
      ...MedicationCheckIn
    }
  }
}
    ${MedicationCheckInFragmentDoc}`;

/**
 * __useCreateMedicationCheckInMutation__
 *
 * To run a mutation, you first call `useCreateMedicationCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicationCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicationCheckInMutation, { data, loading, error }] = useCreateMedicationCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMedicationCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateMedicationCheckInMutation, Types.CreateMedicationCheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateMedicationCheckInMutation, Types.CreateMedicationCheckInMutationVariables>(CreateMedicationCheckInDocument, options);
      }
export type CreateMedicationCheckInMutationHookResult = ReturnType<typeof useCreateMedicationCheckInMutation>;
export type CreateMedicationCheckInMutationResult = ApolloReactCommon.MutationResult<Types.CreateMedicationCheckInMutation>;
export type CreateMedicationCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateMedicationCheckInMutation, Types.CreateMedicationCheckInMutationVariables>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($input: CreateMessageInput!) {
  createMessage(input: $input) {
    messageEdge {
      node {
        id
        createdAt
        updatedAt
        sentDatetime
        recipient
      }
    }
  }
}
    `;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateMessageMutation, Types.CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateMessageMutation, Types.CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = ApolloReactCommon.MutationResult<Types.CreateMessageMutation>;
export type CreateMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateMessageMutation, Types.CreateMessageMutationVariables>;
export const CreateNameDocument = gql`
    mutation CreateName($input: CreateNameInput!, $contactId: UUID!, $contactType: ContactType!) {
  createName(input: $input, contactId: $contactId, contactType: $contactType) {
    name {
      ...FullName
    }
  }
}
    ${FullNameFragmentDoc}`;

/**
 * __useCreateNameMutation__
 *
 * To run a mutation, you first call `useCreateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNameMutation, { data, loading, error }] = useCreateNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactId: // value for 'contactId'
 *      contactType: // value for 'contactType'
 *   },
 * });
 */
export function useCreateNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateNameMutation, Types.CreateNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateNameMutation, Types.CreateNameMutationVariables>(CreateNameDocument, options);
      }
export type CreateNameMutationHookResult = ReturnType<typeof useCreateNameMutation>;
export type CreateNameMutationResult = ApolloReactCommon.MutationResult<Types.CreateNameMutation>;
export type CreateNameMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateNameMutation, Types.CreateNameMutationVariables>;
export const CreatePatientNoteDocument = gql`
    mutation CreatePatientNote($input: CreatePatientNoteInput!) {
  createPatientNote(input: $input) {
    patientNote {
      id
      note
    }
  }
}
    `;

/**
 * __useCreatePatientNoteMutation__
 *
 * To run a mutation, you first call `useCreatePatientNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientNoteMutation, { data, loading, error }] = useCreatePatientNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreatePatientNoteMutation, Types.CreatePatientNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreatePatientNoteMutation, Types.CreatePatientNoteMutationVariables>(CreatePatientNoteDocument, options);
      }
export type CreatePatientNoteMutationHookResult = ReturnType<typeof useCreatePatientNoteMutation>;
export type CreatePatientNoteMutationResult = ApolloReactCommon.MutationResult<Types.CreatePatientNoteMutation>;
export type CreatePatientNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreatePatientNoteMutation, Types.CreatePatientNoteMutationVariables>;
export const CreatePhoneNumberDocument = gql`
    mutation CreatePhoneNumber($input: CreatePhoneNumberInput!, $contactId: UUID!, $contactType: ContactType!) {
  createPhoneNumber(input: $input, contactId: $contactId, contactType: $contactType) {
    phoneNumber {
      ...FullPhoneNumber
    }
  }
}
    ${FullPhoneNumberFragmentDoc}`;

/**
 * __useCreatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useCreatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhoneNumberMutation, { data, loading, error }] = useCreatePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactId: // value for 'contactId'
 *      contactType: // value for 'contactType'
 *   },
 * });
 */
export function useCreatePhoneNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreatePhoneNumberMutation, Types.CreatePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreatePhoneNumberMutation, Types.CreatePhoneNumberMutationVariables>(CreatePhoneNumberDocument, options);
      }
export type CreatePhoneNumberMutationHookResult = ReturnType<typeof useCreatePhoneNumberMutation>;
export type CreatePhoneNumberMutationResult = ApolloReactCommon.MutationResult<Types.CreatePhoneNumberMutation>;
export type CreatePhoneNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreatePhoneNumberMutation, Types.CreatePhoneNumberMutationVariables>;
export const CreateProspectDocument = gql`
    mutation CreateProspect($input: CreateProspectInput!) {
  createProspect(input: $input) {
    prospect {
      id
      person {
        primaryFirstName
        primaryLastName
        primaryPhoneNumber
        birthDate
      }
    }
  }
}
    `;

/**
 * __useCreateProspectMutation__
 *
 * To run a mutation, you first call `useCreateProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProspectMutation, { data, loading, error }] = useCreateProspectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProspectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateProspectMutation, Types.CreateProspectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateProspectMutation, Types.CreateProspectMutationVariables>(CreateProspectDocument, options);
      }
export type CreateProspectMutationHookResult = ReturnType<typeof useCreateProspectMutation>;
export type CreateProspectMutationResult = ApolloReactCommon.MutationResult<Types.CreateProspectMutation>;
export type CreateProspectMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateProspectMutation, Types.CreateProspectMutationVariables>;
export const CreateProspectForInactiveMemberDocument = gql`
    mutation createProspectForInactiveMember($input: CreateProspectForInactiveMemberInput!) {
  createProspectForInactiveMember(input: $input) {
    prospect {
      id
    }
  }
}
    `;

/**
 * __useCreateProspectForInactiveMemberMutation__
 *
 * To run a mutation, you first call `useCreateProspectForInactiveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProspectForInactiveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProspectForInactiveMemberMutation, { data, loading, error }] = useCreateProspectForInactiveMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProspectForInactiveMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateProspectForInactiveMemberMutation, Types.CreateProspectForInactiveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateProspectForInactiveMemberMutation, Types.CreateProspectForInactiveMemberMutationVariables>(CreateProspectForInactiveMemberDocument, options);
      }
export type CreateProspectForInactiveMemberMutationHookResult = ReturnType<typeof useCreateProspectForInactiveMemberMutation>;
export type CreateProspectForInactiveMemberMutationResult = ApolloReactCommon.MutationResult<Types.CreateProspectForInactiveMemberMutation>;
export type CreateProspectForInactiveMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateProspectForInactiveMemberMutation, Types.CreateProspectForInactiveMemberMutationVariables>;
export const CreateProspectNoteDocument = gql`
    mutation CreateProspectNote($input: CreateProspectNoteInput!) {
  createProspectNote(input: $input) {
    prospectNote {
      id
      note
    }
  }
}
    `;

/**
 * __useCreateProspectNoteMutation__
 *
 * To run a mutation, you first call `useCreateProspectNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProspectNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProspectNoteMutation, { data, loading, error }] = useCreateProspectNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProspectNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateProspectNoteMutation, Types.CreateProspectNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateProspectNoteMutation, Types.CreateProspectNoteMutationVariables>(CreateProspectNoteDocument, options);
      }
export type CreateProspectNoteMutationHookResult = ReturnType<typeof useCreateProspectNoteMutation>;
export type CreateProspectNoteMutationResult = ApolloReactCommon.MutationResult<Types.CreateProspectNoteMutation>;
export type CreateProspectNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateProspectNoteMutation, Types.CreateProspectNoteMutationVariables>;
export const CreateReminderDocument = gql`
    mutation CreateReminder($input: CreateReminderInput!) {
  createReminder(input: $input) {
    reminder {
      ...FullReminder
    }
  }
}
    ${FullReminderFragmentDoc}`;

/**
 * __useCreateReminderMutation__
 *
 * To run a mutation, you first call `useCreateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderMutation, { data, loading, error }] = useCreateReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateReminderMutation, Types.CreateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateReminderMutation, Types.CreateReminderMutationVariables>(CreateReminderDocument, options);
      }
export type CreateReminderMutationHookResult = ReturnType<typeof useCreateReminderMutation>;
export type CreateReminderMutationResult = ApolloReactCommon.MutationResult<Types.CreateReminderMutation>;
export type CreateReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateReminderMutation, Types.CreateReminderMutationVariables>;
export const CreateSmsOutreachMessageTemplateDocument = gql`
    mutation createSmsOutreachMessageTemplate($input: CreateSmsOutreachMessageTemplateInput!) {
  createSmsOutreachMessageTemplate(input: $input) {
    smsOutreachMessageTemplate {
      adminId
      message
      id
    }
  }
}
    `;

/**
 * __useCreateSmsOutreachMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateSmsOutreachMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmsOutreachMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmsOutreachMessageTemplateMutation, { data, loading, error }] = useCreateSmsOutreachMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSmsOutreachMessageTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateSmsOutreachMessageTemplateMutation, Types.CreateSmsOutreachMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreateSmsOutreachMessageTemplateMutation, Types.CreateSmsOutreachMessageTemplateMutationVariables>(CreateSmsOutreachMessageTemplateDocument, options);
      }
export type CreateSmsOutreachMessageTemplateMutationHookResult = ReturnType<typeof useCreateSmsOutreachMessageTemplateMutation>;
export type CreateSmsOutreachMessageTemplateMutationResult = ApolloReactCommon.MutationResult<Types.CreateSmsOutreachMessageTemplateMutation>;
export type CreateSmsOutreachMessageTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateSmsOutreachMessageTemplateMutation, Types.CreateSmsOutreachMessageTemplateMutationVariables>;
export const CreditAppointmentDocument = gql`
    mutation CreditAppointment($input: CreditAppointmentInput!) {
  creditAppointment(input: $input) {
    ...FullAppointment
  }
}
    ${FullAppointmentFragmentDoc}`;

/**
 * __useCreditAppointmentMutation__
 *
 * To run a mutation, you first call `useCreditAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditAppointmentMutation, { data, loading, error }] = useCreditAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreditAppointmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreditAppointmentMutation, Types.CreditAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CreditAppointmentMutation, Types.CreditAppointmentMutationVariables>(CreditAppointmentDocument, options);
      }
export type CreditAppointmentMutationHookResult = ReturnType<typeof useCreditAppointmentMutation>;
export type CreditAppointmentMutationResult = ApolloReactCommon.MutationResult<Types.CreditAppointmentMutation>;
export type CreditAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreditAppointmentMutation, Types.CreditAppointmentMutationVariables>;
export const DeleteEmailAddressDocument = gql`
    mutation DeleteEmailAddress($id: UUID!) {
  deleteEmailAddressById(id: $id)
}
    `;

/**
 * __useDeleteEmailAddressMutation__
 *
 * To run a mutation, you first call `useDeleteEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailAddressMutation, { data, loading, error }] = useDeleteEmailAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.DeleteEmailAddressMutation, Types.DeleteEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.DeleteEmailAddressMutation, Types.DeleteEmailAddressMutationVariables>(DeleteEmailAddressDocument, options);
      }
export type DeleteEmailAddressMutationHookResult = ReturnType<typeof useDeleteEmailAddressMutation>;
export type DeleteEmailAddressMutationResult = ApolloReactCommon.MutationResult<Types.DeleteEmailAddressMutation>;
export type DeleteEmailAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.DeleteEmailAddressMutation, Types.DeleteEmailAddressMutationVariables>;
export const DeleteMailingAddressDocument = gql`
    mutation DeleteMailingAddress($id: UUID!) {
  deleteAddressById(id: $id)
}
    `;

/**
 * __useDeleteMailingAddressMutation__
 *
 * To run a mutation, you first call `useDeleteMailingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailingAddressMutation, { data, loading, error }] = useDeleteMailingAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMailingAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.DeleteMailingAddressMutation, Types.DeleteMailingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.DeleteMailingAddressMutation, Types.DeleteMailingAddressMutationVariables>(DeleteMailingAddressDocument, options);
      }
export type DeleteMailingAddressMutationHookResult = ReturnType<typeof useDeleteMailingAddressMutation>;
export type DeleteMailingAddressMutationResult = ApolloReactCommon.MutationResult<Types.DeleteMailingAddressMutation>;
export type DeleteMailingAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.DeleteMailingAddressMutation, Types.DeleteMailingAddressMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation DeleteMember($input: DeleteMemberInput!) {
  deleteMember(input: $input)
}
    `;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.DeleteMemberMutation, Types.DeleteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.DeleteMemberMutation, Types.DeleteMemberMutationVariables>(DeleteMemberDocument, options);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = ApolloReactCommon.MutationResult<Types.DeleteMemberMutation>;
export type DeleteMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.DeleteMemberMutation, Types.DeleteMemberMutationVariables>;
export const DeleteNameDocument = gql`
    mutation DeleteName($id: UUID!) {
  deleteNameById(id: $id)
}
    `;

/**
 * __useDeleteNameMutation__
 *
 * To run a mutation, you first call `useDeleteNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNameMutation, { data, loading, error }] = useDeleteNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.DeleteNameMutation, Types.DeleteNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.DeleteNameMutation, Types.DeleteNameMutationVariables>(DeleteNameDocument, options);
      }
export type DeleteNameMutationHookResult = ReturnType<typeof useDeleteNameMutation>;
export type DeleteNameMutationResult = ApolloReactCommon.MutationResult<Types.DeleteNameMutation>;
export type DeleteNameMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.DeleteNameMutation, Types.DeleteNameMutationVariables>;
export const DeletePhoneNumberDocument = gql`
    mutation DeletePhoneNumber($id: UUID!) {
  deletePhoneNumberById(id: $id)
}
    `;

/**
 * __useDeletePhoneNumberMutation__
 *
 * To run a mutation, you first call `useDeletePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneNumberMutation, { data, loading, error }] = useDeletePhoneNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.DeletePhoneNumberMutation, Types.DeletePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.DeletePhoneNumberMutation, Types.DeletePhoneNumberMutationVariables>(DeletePhoneNumberDocument, options);
      }
export type DeletePhoneNumberMutationHookResult = ReturnType<typeof useDeletePhoneNumberMutation>;
export type DeletePhoneNumberMutationResult = ApolloReactCommon.MutationResult<Types.DeletePhoneNumberMutation>;
export type DeletePhoneNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.DeletePhoneNumberMutation, Types.DeletePhoneNumberMutationVariables>;
export const DeleteReminderDocument = gql`
    mutation DeleteReminder($uuid: UUID!) {
  deleteReminderById(input: {id: $uuid}) {
    deletedReminderId
  }
}
    `;

/**
 * __useDeleteReminderMutation__
 *
 * To run a mutation, you first call `useDeleteReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReminderMutation, { data, loading, error }] = useDeleteReminderMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.DeleteReminderMutation, Types.DeleteReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.DeleteReminderMutation, Types.DeleteReminderMutationVariables>(DeleteReminderDocument, options);
      }
export type DeleteReminderMutationHookResult = ReturnType<typeof useDeleteReminderMutation>;
export type DeleteReminderMutationResult = ApolloReactCommon.MutationResult<Types.DeleteReminderMutation>;
export type DeleteReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.DeleteReminderMutation, Types.DeleteReminderMutationVariables>;
export const EnrollMemberDocument = gql`
    mutation EnrollMember($input: EnrollMemberInput!) {
  enrollMember(input: $input) {
    memberId
  }
}
    `;

/**
 * __useEnrollMemberMutation__
 *
 * To run a mutation, you first call `useEnrollMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollMemberMutation, { data, loading, error }] = useEnrollMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.EnrollMemberMutation, Types.EnrollMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.EnrollMemberMutation, Types.EnrollMemberMutationVariables>(EnrollMemberDocument, options);
      }
export type EnrollMemberMutationHookResult = ReturnType<typeof useEnrollMemberMutation>;
export type EnrollMemberMutationResult = ApolloReactCommon.MutationResult<Types.EnrollMemberMutation>;
export type EnrollMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.EnrollMemberMutation, Types.EnrollMemberMutationVariables>;
export const EnrollPaperMutationDocument = gql`
    mutation EnrollPaperMutation($input: EnrollPaperMemberInput!) {
  enrollPaperMember(input: $input) {
    memberId
  }
}
    `;

/**
 * __useEnrollPaperMutationMutation__
 *
 * To run a mutation, you first call `useEnrollPaperMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollPaperMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollPaperMutationMutation, { data, loading, error }] = useEnrollPaperMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollPaperMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.EnrollPaperMutationMutation, Types.EnrollPaperMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.EnrollPaperMutationMutation, Types.EnrollPaperMutationMutationVariables>(EnrollPaperMutationDocument, options);
      }
export type EnrollPaperMutationMutationHookResult = ReturnType<typeof useEnrollPaperMutationMutation>;
export type EnrollPaperMutationMutationResult = ApolloReactCommon.MutationResult<Types.EnrollPaperMutationMutation>;
export type EnrollPaperMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.EnrollPaperMutationMutation, Types.EnrollPaperMutationMutationVariables>;
export const GetTokenViaSsoDocument = gql`
    mutation GetTokenViaSSO($provider: String!, $identityToken: String!) {
  getTokenViaSSO(provider: $provider, identityToken: $identityToken) {
    jwtToken
  }
}
    `;

/**
 * __useGetTokenViaSsoMutation__
 *
 * To run a mutation, you first call `useGetTokenViaSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTokenViaSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTokenViaSsoMutation, { data, loading, error }] = useGetTokenViaSsoMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      identityToken: // value for 'identityToken'
 *   },
 * });
 */
export function useGetTokenViaSsoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.GetTokenViaSsoMutation, Types.GetTokenViaSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.GetTokenViaSsoMutation, Types.GetTokenViaSsoMutationVariables>(GetTokenViaSsoDocument, options);
      }
export type GetTokenViaSsoMutationHookResult = ReturnType<typeof useGetTokenViaSsoMutation>;
export type GetTokenViaSsoMutationResult = ApolloReactCommon.MutationResult<Types.GetTokenViaSsoMutation>;
export type GetTokenViaSsoMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.GetTokenViaSsoMutation, Types.GetTokenViaSsoMutationVariables>;
export const SubmitPauseRequestDocument = gql`
    mutation submitPauseRequest($input: SubmitPauseRequestInput!) {
  submitPauseRequest(input: $input) {
    boolean
  }
}
    `;

/**
 * __useSubmitPauseRequestMutation__
 *
 * To run a mutation, you first call `useSubmitPauseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPauseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPauseRequestMutation, { data, loading, error }] = useSubmitPauseRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPauseRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SubmitPauseRequestMutation, Types.SubmitPauseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.SubmitPauseRequestMutation, Types.SubmitPauseRequestMutationVariables>(SubmitPauseRequestDocument, options);
      }
export type SubmitPauseRequestMutationHookResult = ReturnType<typeof useSubmitPauseRequestMutation>;
export type SubmitPauseRequestMutationResult = ApolloReactCommon.MutationResult<Types.SubmitPauseRequestMutation>;
export type SubmitPauseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.SubmitPauseRequestMutation, Types.SubmitPauseRequestMutationVariables>;
export const ReauthenticatePatientDocument = gql`
    mutation ReauthenticatePatient($token: String!) {
  reauthenticatePatient(input: {refreshToken: $token}) {
    jwtToken
  }
}
    `;

/**
 * __useReauthenticatePatientMutation__
 *
 * To run a mutation, you first call `useReauthenticatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReauthenticatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reauthenticatePatientMutation, { data, loading, error }] = useReauthenticatePatientMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useReauthenticatePatientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ReauthenticatePatientMutation, Types.ReauthenticatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.ReauthenticatePatientMutation, Types.ReauthenticatePatientMutationVariables>(ReauthenticatePatientDocument, options);
      }
export type ReauthenticatePatientMutationHookResult = ReturnType<typeof useReauthenticatePatientMutation>;
export type ReauthenticatePatientMutationResult = ApolloReactCommon.MutationResult<Types.ReauthenticatePatientMutation>;
export type ReauthenticatePatientMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.ReauthenticatePatientMutation, Types.ReauthenticatePatientMutationVariables>;
export const ReindexAllPersonsDocument = gql`
    mutation ReindexAllPersons {
  reindexAllPersons {
    status
    message
  }
}
    `;

/**
 * __useReindexAllPersonsMutation__
 *
 * To run a mutation, you first call `useReindexAllPersonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexAllPersonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexAllPersonsMutation, { data, loading, error }] = useReindexAllPersonsMutation({
 *   variables: {
 *   },
 * });
 */
export function useReindexAllPersonsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ReindexAllPersonsMutation, Types.ReindexAllPersonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.ReindexAllPersonsMutation, Types.ReindexAllPersonsMutationVariables>(ReindexAllPersonsDocument, options);
      }
export type ReindexAllPersonsMutationHookResult = ReturnType<typeof useReindexAllPersonsMutation>;
export type ReindexAllPersonsMutationResult = ApolloReactCommon.MutationResult<Types.ReindexAllPersonsMutation>;
export type ReindexAllPersonsMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.ReindexAllPersonsMutation, Types.ReindexAllPersonsMutationVariables>;
export const RemoveIdentityTagDocument = gql`
    mutation RemoveIdentityTag($input: RemoveTagInput!) {
  removeIdentityTag(input: $input)
}
    `;

/**
 * __useRemoveIdentityTagMutation__
 *
 * To run a mutation, you first call `useRemoveIdentityTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIdentityTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIdentityTagMutation, { data, loading, error }] = useRemoveIdentityTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveIdentityTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.RemoveIdentityTagMutation, Types.RemoveIdentityTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.RemoveIdentityTagMutation, Types.RemoveIdentityTagMutationVariables>(RemoveIdentityTagDocument, options);
      }
export type RemoveIdentityTagMutationHookResult = ReturnType<typeof useRemoveIdentityTagMutation>;
export type RemoveIdentityTagMutationResult = ApolloReactCommon.MutationResult<Types.RemoveIdentityTagMutation>;
export type RemoveIdentityTagMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.RemoveIdentityTagMutation, Types.RemoveIdentityTagMutationVariables>;
export const SendOneOffMessageDocument = gql`
    mutation sendOneOffMessage($input: OneOffSenderInput!) {
  sendOneOffMessage(input: $input)
}
    `;

/**
 * __useSendOneOffMessageMutation__
 *
 * To run a mutation, you first call `useSendOneOffMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOneOffMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOneOffMessageMutation, { data, loading, error }] = useSendOneOffMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOneOffMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SendOneOffMessageMutation, Types.SendOneOffMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.SendOneOffMessageMutation, Types.SendOneOffMessageMutationVariables>(SendOneOffMessageDocument, options);
      }
export type SendOneOffMessageMutationHookResult = ReturnType<typeof useSendOneOffMessageMutation>;
export type SendOneOffMessageMutationResult = ApolloReactCommon.MutationResult<Types.SendOneOffMessageMutation>;
export type SendOneOffMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.SendOneOffMessageMutation, Types.SendOneOffMessageMutationVariables>;
export const TestSmsMessageDocument = gql`
    mutation testSmsMessage($recipient: SmsTestMessageRecipientInput!, $messageTemplateId: String!) {
  testSmsMessage(recipient: $recipient, messageTemplateId: $messageTemplateId)
}
    `;

/**
 * __useTestSmsMessageMutation__
 *
 * To run a mutation, you first call `useTestSmsMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestSmsMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testSmsMessageMutation, { data, loading, error }] = useTestSmsMessageMutation({
 *   variables: {
 *      recipient: // value for 'recipient'
 *      messageTemplateId: // value for 'messageTemplateId'
 *   },
 * });
 */
export function useTestSmsMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.TestSmsMessageMutation, Types.TestSmsMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.TestSmsMessageMutation, Types.TestSmsMessageMutationVariables>(TestSmsMessageDocument, options);
      }
export type TestSmsMessageMutationHookResult = ReturnType<typeof useTestSmsMessageMutation>;
export type TestSmsMessageMutationResult = ApolloReactCommon.MutationResult<Types.TestSmsMessageMutation>;
export type TestSmsMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.TestSmsMessageMutation, Types.TestSmsMessageMutationVariables>;
export const SubmitUnpauseRequestDocument = gql`
    mutation submitUnpauseRequest($input: SubmitUnpauseRequestInput!) {
  submitUnpauseRequest(input: $input) {
    boolean
  }
}
    `;

/**
 * __useSubmitUnpauseRequestMutation__
 *
 * To run a mutation, you first call `useSubmitUnpauseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUnpauseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUnpauseRequestMutation, { data, loading, error }] = useSubmitUnpauseRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitUnpauseRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SubmitUnpauseRequestMutation, Types.SubmitUnpauseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.SubmitUnpauseRequestMutation, Types.SubmitUnpauseRequestMutationVariables>(SubmitUnpauseRequestDocument, options);
      }
export type SubmitUnpauseRequestMutationHookResult = ReturnType<typeof useSubmitUnpauseRequestMutation>;
export type SubmitUnpauseRequestMutationResult = ApolloReactCommon.MutationResult<Types.SubmitUnpauseRequestMutation>;
export type SubmitUnpauseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.SubmitUnpauseRequestMutation, Types.SubmitUnpauseRequestMutationVariables>;
export const UpdateCheckInDocument = gql`
    mutation UpdateCheckIn($id: UUID!, $checkInPatch: CheckInPatch!) {
  updateCheckInById(input: {id: $id, checkInPatch: $checkInPatch}) {
    checkIn {
      id
      checkInTimestamp
      checkInTimezone
      photoUrl
      verificationStatus
      category
      isCredited
    }
  }
}
    `;

/**
 * __useUpdateCheckInMutation__
 *
 * To run a mutation, you first call `useUpdateCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckInMutation, { data, loading, error }] = useUpdateCheckInMutation({
 *   variables: {
 *      id: // value for 'id'
 *      checkInPatch: // value for 'checkInPatch'
 *   },
 * });
 */
export function useUpdateCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateCheckInMutation, Types.UpdateCheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateCheckInMutation, Types.UpdateCheckInMutationVariables>(UpdateCheckInDocument, options);
      }
export type UpdateCheckInMutationHookResult = ReturnType<typeof useUpdateCheckInMutation>;
export type UpdateCheckInMutationResult = ApolloReactCommon.MutationResult<Types.UpdateCheckInMutation>;
export type UpdateCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateCheckInMutation, Types.UpdateCheckInMutationVariables>;
export const UpdateEmailAddressDocument = gql`
    mutation UpdateEmailAddress($input: UpdateEmailAddressInput!) {
  updateEmailAddress(input: $input) {
    emailAddress {
      ...Email
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useUpdateEmailAddressMutation__
 *
 * To run a mutation, you first call `useUpdateEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailAddressMutation, { data, loading, error }] = useUpdateEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateEmailAddressMutation, Types.UpdateEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateEmailAddressMutation, Types.UpdateEmailAddressMutationVariables>(UpdateEmailAddressDocument, options);
      }
export type UpdateEmailAddressMutationHookResult = ReturnType<typeof useUpdateEmailAddressMutation>;
export type UpdateEmailAddressMutationResult = ApolloReactCommon.MutationResult<Types.UpdateEmailAddressMutation>;
export type UpdateEmailAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateEmailAddressMutation, Types.UpdateEmailAddressMutationVariables>;
export const UpdateMailingAddressDocument = gql`
    mutation UpdateMailingAddress($input: UpdateAddressInput!) {
  updateAddress(input: $input) {
    address {
      ...FullMailingAddress
    }
  }
}
    ${FullMailingAddressFragmentDoc}`;

/**
 * __useUpdateMailingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateMailingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailingAddressMutation, { data, loading, error }] = useUpdateMailingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMailingAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateMailingAddressMutation, Types.UpdateMailingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateMailingAddressMutation, Types.UpdateMailingAddressMutationVariables>(UpdateMailingAddressDocument, options);
      }
export type UpdateMailingAddressMutationHookResult = ReturnType<typeof useUpdateMailingAddressMutation>;
export type UpdateMailingAddressMutationResult = ApolloReactCommon.MutationResult<Types.UpdateMailingAddressMutation>;
export type UpdateMailingAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateMailingAddressMutation, Types.UpdateMailingAddressMutationVariables>;
export const UpdateMemberByIdDocument = gql`
    mutation UpdateMemberById($id: UUID!, $memberPatch: MemberPatch!) {
  updateMemberById(id: $id, memberPatch: $memberPatch) {
    id
    siteName
    externalId
    person {
      ...PersonProfileInformation
    }
  }
}
    ${PersonProfileInformationFragmentDoc}`;

/**
 * __useUpdateMemberByIdMutation__
 *
 * To run a mutation, you first call `useUpdateMemberByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberByIdMutation, { data, loading, error }] = useUpdateMemberByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberPatch: // value for 'memberPatch'
 *   },
 * });
 */
export function useUpdateMemberByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateMemberByIdMutation, Types.UpdateMemberByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateMemberByIdMutation, Types.UpdateMemberByIdMutationVariables>(UpdateMemberByIdDocument, options);
      }
export type UpdateMemberByIdMutationHookResult = ReturnType<typeof useUpdateMemberByIdMutation>;
export type UpdateMemberByIdMutationResult = ApolloReactCommon.MutationResult<Types.UpdateMemberByIdMutation>;
export type UpdateMemberByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateMemberByIdMutation, Types.UpdateMemberByIdMutationVariables>;
export const UpdateNameDocument = gql`
    mutation UpdateName($input: UpdateNameInput!) {
  updateName(input: $input) {
    name {
      ...FullName
    }
  }
}
    ${FullNameFragmentDoc}`;

/**
 * __useUpdateNameMutation__
 *
 * To run a mutation, you first call `useUpdateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNameMutation, { data, loading, error }] = useUpdateNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateNameMutation, Types.UpdateNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateNameMutation, Types.UpdateNameMutationVariables>(UpdateNameDocument, options);
      }
export type UpdateNameMutationHookResult = ReturnType<typeof useUpdateNameMutation>;
export type UpdateNameMutationResult = ApolloReactCommon.MutationResult<Types.UpdateNameMutation>;
export type UpdateNameMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateNameMutation, Types.UpdateNameMutationVariables>;
export const UpdatePauseObjectDocument = gql`
    mutation updatePauseObject($pausePatch: PausePatch!) {
  updatePauseObject(pausePatch: $pausePatch)
}
    `;

/**
 * __useUpdatePauseObjectMutation__
 *
 * To run a mutation, you first call `useUpdatePauseObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePauseObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePauseObjectMutation, { data, loading, error }] = useUpdatePauseObjectMutation({
 *   variables: {
 *      pausePatch: // value for 'pausePatch'
 *   },
 * });
 */
export function useUpdatePauseObjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdatePauseObjectMutation, Types.UpdatePauseObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdatePauseObjectMutation, Types.UpdatePauseObjectMutationVariables>(UpdatePauseObjectDocument, options);
      }
export type UpdatePauseObjectMutationHookResult = ReturnType<typeof useUpdatePauseObjectMutation>;
export type UpdatePauseObjectMutationResult = ApolloReactCommon.MutationResult<Types.UpdatePauseObjectMutation>;
export type UpdatePauseObjectMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdatePauseObjectMutation, Types.UpdatePauseObjectMutationVariables>;
export const UpdatePhoneNumberDocument = gql`
    mutation UpdatePhoneNumber($input: UpdatePhoneNumberInput!) {
  updatePhoneNumber(input: $input) {
    phoneNumber {
      ...FullPhoneNumber
    }
  }
}
    ${FullPhoneNumberFragmentDoc}`;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdatePhoneNumberMutation, Types.UpdatePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdatePhoneNumberMutation, Types.UpdatePhoneNumberMutationVariables>(UpdatePhoneNumberDocument, options);
      }
export type UpdatePhoneNumberMutationHookResult = ReturnType<typeof useUpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationResult = ApolloReactCommon.MutationResult<Types.UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdatePhoneNumberMutation, Types.UpdatePhoneNumberMutationVariables>;
export const UpdateProgrambyIdDocument = gql`
    mutation updateProgrambyId($id: String!, $programPatch: ProgramPatch!) {
  updateProgramById(id: $id, programPatch: $programPatch) {
    id
  }
}
    `;

/**
 * __useUpdateProgrambyIdMutation__
 *
 * To run a mutation, you first call `useUpdateProgrambyIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgrambyIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgrambyIdMutation, { data, loading, error }] = useUpdateProgrambyIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      programPatch: // value for 'programPatch'
 *   },
 * });
 */
export function useUpdateProgrambyIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateProgrambyIdMutation, Types.UpdateProgrambyIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateProgrambyIdMutation, Types.UpdateProgrambyIdMutationVariables>(UpdateProgrambyIdDocument, options);
      }
export type UpdateProgrambyIdMutationHookResult = ReturnType<typeof useUpdateProgrambyIdMutation>;
export type UpdateProgrambyIdMutationResult = ApolloReactCommon.MutationResult<Types.UpdateProgrambyIdMutation>;
export type UpdateProgrambyIdMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateProgrambyIdMutation, Types.UpdateProgrambyIdMutationVariables>;
export const UpdateProspectByIdDocument = gql`
    mutation UpdateProspectById($id: UUID!, $prospectPatch: ProspectPatch!) {
  updateProspectById(id: $id, prospectPatch: $prospectPatch) {
    id
    siteName
    externalId
    description
    eligibilityEndDate
    person {
      ...PersonProfileInformation
    }
    program {
      programCode
    }
    prospectStatuses {
      value
    }
  }
}
    ${PersonProfileInformationFragmentDoc}`;

/**
 * __useUpdateProspectByIdMutation__
 *
 * To run a mutation, you first call `useUpdateProspectByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProspectByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProspectByIdMutation, { data, loading, error }] = useUpdateProspectByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      prospectPatch: // value for 'prospectPatch'
 *   },
 * });
 */
export function useUpdateProspectByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateProspectByIdMutation, Types.UpdateProspectByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateProspectByIdMutation, Types.UpdateProspectByIdMutationVariables>(UpdateProspectByIdDocument, options);
      }
export type UpdateProspectByIdMutationHookResult = ReturnType<typeof useUpdateProspectByIdMutation>;
export type UpdateProspectByIdMutationResult = ApolloReactCommon.MutationResult<Types.UpdateProspectByIdMutation>;
export type UpdateProspectByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateProspectByIdMutation, Types.UpdateProspectByIdMutationVariables>;
export const UpdateReminderDocument = gql`
    mutation UpdateReminder($uuid: UUID!, $reminderPatch: ReminderPatch!) {
  updateReminderById(input: {id: $uuid, reminderPatch: $reminderPatch}) {
    reminder {
      id
      nodeId
      startDate
      endDate
      monTime
      tueTime
      wedTime
      thuTime
      friTime
      satTime
      sunTime
      isDemo
    }
  }
}
    `;

/**
 * __useUpdateReminderMutation__
 *
 * To run a mutation, you first call `useUpdateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderMutation, { data, loading, error }] = useUpdateReminderMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      reminderPatch: // value for 'reminderPatch'
 *   },
 * });
 */
export function useUpdateReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateReminderMutation, Types.UpdateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateReminderMutation, Types.UpdateReminderMutationVariables>(UpdateReminderDocument, options);
      }
export type UpdateReminderMutationHookResult = ReturnType<typeof useUpdateReminderMutation>;
export type UpdateReminderMutationResult = ApolloReactCommon.MutationResult<Types.UpdateReminderMutation>;
export type UpdateReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateReminderMutation, Types.UpdateReminderMutationVariables>;
export const UpdateSmsOutreachMessageTemplateByIdDocument = gql`
    mutation updateSmsOutreachMessageTemplateById($input: UpdateSmsOutreachMessageTemplateByIdInput!) {
  updateSmsOutreachMessageTemplateById(input: $input) {
    smsOutreachMessageTemplate {
      message
    }
  }
}
    `;

/**
 * __useUpdateSmsOutreachMessageTemplateByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSmsOutreachMessageTemplateByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsOutreachMessageTemplateByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsOutreachMessageTemplateByIdMutation, { data, loading, error }] = useUpdateSmsOutreachMessageTemplateByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSmsOutreachMessageTemplateByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateSmsOutreachMessageTemplateByIdMutation, Types.UpdateSmsOutreachMessageTemplateByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UpdateSmsOutreachMessageTemplateByIdMutation, Types.UpdateSmsOutreachMessageTemplateByIdMutationVariables>(UpdateSmsOutreachMessageTemplateByIdDocument, options);
      }
export type UpdateSmsOutreachMessageTemplateByIdMutationHookResult = ReturnType<typeof useUpdateSmsOutreachMessageTemplateByIdMutation>;
export type UpdateSmsOutreachMessageTemplateByIdMutationResult = ApolloReactCommon.MutationResult<Types.UpdateSmsOutreachMessageTemplateByIdMutation>;
export type UpdateSmsOutreachMessageTemplateByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateSmsOutreachMessageTemplateByIdMutation, Types.UpdateSmsOutreachMessageTemplateByIdMutationVariables>;
export const UploadBulkSmsDocument = gql`
    mutation uploadBulkSms($file: Upload!, $messageTemplateId: UUID!) {
  uploadBulkSms(file: $file, messageTemplateId: $messageTemplateId)
}
    `;

/**
 * __useUploadBulkSmsMutation__
 *
 * To run a mutation, you first call `useUploadBulkSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBulkSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBulkSmsMutation, { data, loading, error }] = useUploadBulkSmsMutation({
 *   variables: {
 *      file: // value for 'file'
 *      messageTemplateId: // value for 'messageTemplateId'
 *   },
 * });
 */
export function useUploadBulkSmsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UploadBulkSmsMutation, Types.UploadBulkSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UploadBulkSmsMutation, Types.UploadBulkSmsMutationVariables>(UploadBulkSmsDocument, options);
      }
export type UploadBulkSmsMutationHookResult = ReturnType<typeof useUploadBulkSmsMutation>;
export type UploadBulkSmsMutationResult = ApolloReactCommon.MutationResult<Types.UploadBulkSmsMutation>;
export type UploadBulkSmsMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UploadBulkSmsMutation, Types.UploadBulkSmsMutationVariables>;
export const UploadProspectFileDocument = gql`
    mutation uploadProspectFile($file: Upload!, $programId: UUID!) {
  uploadProspectFile(file: $file, programId: $programId)
}
    `;

/**
 * __useUploadProspectFileMutation__
 *
 * To run a mutation, you first call `useUploadProspectFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProspectFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProspectFileMutation, { data, loading, error }] = useUploadProspectFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useUploadProspectFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UploadProspectFileMutation, Types.UploadProspectFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.UploadProspectFileMutation, Types.UploadProspectFileMutationVariables>(UploadProspectFileDocument, options);
      }
export type UploadProspectFileMutationHookResult = ReturnType<typeof useUploadProspectFileMutation>;
export type UploadProspectFileMutationResult = ApolloReactCommon.MutationResult<Types.UploadProspectFileMutation>;
export type UploadProspectFileMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UploadProspectFileMutation, Types.UploadProspectFileMutationVariables>;
export const ActiveIdentityTagsByWellthIdDocument = gql`
    query activeIdentityTagsByWellthId($wellthId: UUID!) {
  activeIdentityTagsByWellthId(id: $wellthId) {
    id
    name
    backgroundColor
    borderColor
    isAdminRemovable
  }
}
    `;

/**
 * __useActiveIdentityTagsByWellthIdQuery__
 *
 * To run a query within a React component, call `useActiveIdentityTagsByWellthIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveIdentityTagsByWellthIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveIdentityTagsByWellthIdQuery({
 *   variables: {
 *      wellthId: // value for 'wellthId'
 *   },
 * });
 */
export function useActiveIdentityTagsByWellthIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ActiveIdentityTagsByWellthIdQuery, Types.ActiveIdentityTagsByWellthIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ActiveIdentityTagsByWellthIdQuery, Types.ActiveIdentityTagsByWellthIdQueryVariables>(ActiveIdentityTagsByWellthIdDocument, options);
      }
export function useActiveIdentityTagsByWellthIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ActiveIdentityTagsByWellthIdQuery, Types.ActiveIdentityTagsByWellthIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ActiveIdentityTagsByWellthIdQuery, Types.ActiveIdentityTagsByWellthIdQueryVariables>(ActiveIdentityTagsByWellthIdDocument, options);
        }
export type ActiveIdentityTagsByWellthIdQueryHookResult = ReturnType<typeof useActiveIdentityTagsByWellthIdQuery>;
export type ActiveIdentityTagsByWellthIdLazyQueryHookResult = ReturnType<typeof useActiveIdentityTagsByWellthIdLazyQuery>;
export type ActiveIdentityTagsByWellthIdQueryResult = ApolloReactCommon.QueryResult<Types.ActiveIdentityTagsByWellthIdQuery, Types.ActiveIdentityTagsByWellthIdQueryVariables>;
export const AdherenceCurrentRewardPeriodDocument = gql`
    query AdherenceCurrentRewardPeriod($id: UUID!, $periodStart: Date!, $periodEnd: Date!) {
  member(id: $id) {
    id
    __typename
    adherence(startDate: $periodStart, endDate: $periodEnd) {
      creditedCheckInCount
    }
  }
}
    `;

/**
 * __useAdherenceCurrentRewardPeriodQuery__
 *
 * To run a query within a React component, call `useAdherenceCurrentRewardPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdherenceCurrentRewardPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdherenceCurrentRewardPeriodQuery({
 *   variables: {
 *      id: // value for 'id'
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *   },
 * });
 */
export function useAdherenceCurrentRewardPeriodQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.AdherenceCurrentRewardPeriodQuery, Types.AdherenceCurrentRewardPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AdherenceCurrentRewardPeriodQuery, Types.AdherenceCurrentRewardPeriodQueryVariables>(AdherenceCurrentRewardPeriodDocument, options);
      }
export function useAdherenceCurrentRewardPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AdherenceCurrentRewardPeriodQuery, Types.AdherenceCurrentRewardPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AdherenceCurrentRewardPeriodQuery, Types.AdherenceCurrentRewardPeriodQueryVariables>(AdherenceCurrentRewardPeriodDocument, options);
        }
export type AdherenceCurrentRewardPeriodQueryHookResult = ReturnType<typeof useAdherenceCurrentRewardPeriodQuery>;
export type AdherenceCurrentRewardPeriodLazyQueryHookResult = ReturnType<typeof useAdherenceCurrentRewardPeriodLazyQuery>;
export type AdherenceCurrentRewardPeriodQueryResult = ApolloReactCommon.QueryResult<Types.AdherenceCurrentRewardPeriodQuery, Types.AdherenceCurrentRewardPeriodQueryVariables>;
export const AllAccountTransactionsDocument = gql`
    query allAccountTransactions($memberId: UUID!, $orderBy: [AccountTransactionsOrderBy!]) {
  allAccountTransactions(condition: {patientId: $memberId}, orderBy: $orderBy) {
    nodes {
      id
      date
      amount
      currency
      reason
      note
    }
  }
}
    `;

/**
 * __useAllAccountTransactionsQuery__
 *
 * To run a query within a React component, call `useAllAccountTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAccountTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAccountTransactionsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllAccountTransactionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.AllAccountTransactionsQuery, Types.AllAccountTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllAccountTransactionsQuery, Types.AllAccountTransactionsQueryVariables>(AllAccountTransactionsDocument, options);
      }
export function useAllAccountTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllAccountTransactionsQuery, Types.AllAccountTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllAccountTransactionsQuery, Types.AllAccountTransactionsQueryVariables>(AllAccountTransactionsDocument, options);
        }
export type AllAccountTransactionsQueryHookResult = ReturnType<typeof useAllAccountTransactionsQuery>;
export type AllAccountTransactionsLazyQueryHookResult = ReturnType<typeof useAllAccountTransactionsLazyQuery>;
export type AllAccountTransactionsQueryResult = ApolloReactCommon.QueryResult<Types.AllAccountTransactionsQuery, Types.AllAccountTransactionsQueryVariables>;
export const AllAdminTagConfigsDocument = gql`
    query allAdminTagConfigs {
  allAdminTagConfigs {
    id
    name
  }
}
    `;

/**
 * __useAllAdminTagConfigsQuery__
 *
 * To run a query within a React component, call `useAllAdminTagConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAdminTagConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAdminTagConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAdminTagConfigsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllAdminTagConfigsQuery, Types.AllAdminTagConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllAdminTagConfigsQuery, Types.AllAdminTagConfigsQueryVariables>(AllAdminTagConfigsDocument, options);
      }
export function useAllAdminTagConfigsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllAdminTagConfigsQuery, Types.AllAdminTagConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllAdminTagConfigsQuery, Types.AllAdminTagConfigsQueryVariables>(AllAdminTagConfigsDocument, options);
        }
export type AllAdminTagConfigsQueryHookResult = ReturnType<typeof useAllAdminTagConfigsQuery>;
export type AllAdminTagConfigsLazyQueryHookResult = ReturnType<typeof useAllAdminTagConfigsLazyQuery>;
export type AllAdminTagConfigsQueryResult = ApolloReactCommon.QueryResult<Types.AllAdminTagConfigsQuery, Types.AllAdminTagConfigsQueryVariables>;
export const AllBonusBehaviorSubmissionPayloadsDocument = gql`
    query allBonusBehaviorSubmissionPayloads($statuses: [BonusBehaviorSubmissionStatusValue]!, $after: Cursor, $first: Int) {
  allBonusBehaviorSubmissionPayloads(statuses: $statuses, after: $after, first: $first) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        name
        shortId
        memberId
        status
        timestamp
        firstName
        lastName
        programCode
        rewardAmount
        rewardCurrency
        photoUrl
      }
      cursor
    }
  }
}
    `;

/**
 * __useAllBonusBehaviorSubmissionPayloadsQuery__
 *
 * To run a query within a React component, call `useAllBonusBehaviorSubmissionPayloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBonusBehaviorSubmissionPayloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBonusBehaviorSubmissionPayloadsQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllBonusBehaviorSubmissionPayloadsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.AllBonusBehaviorSubmissionPayloadsQuery, Types.AllBonusBehaviorSubmissionPayloadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllBonusBehaviorSubmissionPayloadsQuery, Types.AllBonusBehaviorSubmissionPayloadsQueryVariables>(AllBonusBehaviorSubmissionPayloadsDocument, options);
      }
export function useAllBonusBehaviorSubmissionPayloadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllBonusBehaviorSubmissionPayloadsQuery, Types.AllBonusBehaviorSubmissionPayloadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllBonusBehaviorSubmissionPayloadsQuery, Types.AllBonusBehaviorSubmissionPayloadsQueryVariables>(AllBonusBehaviorSubmissionPayloadsDocument, options);
        }
export type AllBonusBehaviorSubmissionPayloadsQueryHookResult = ReturnType<typeof useAllBonusBehaviorSubmissionPayloadsQuery>;
export type AllBonusBehaviorSubmissionPayloadsLazyQueryHookResult = ReturnType<typeof useAllBonusBehaviorSubmissionPayloadsLazyQuery>;
export type AllBonusBehaviorSubmissionPayloadsQueryResult = ApolloReactCommon.QueryResult<Types.AllBonusBehaviorSubmissionPayloadsQuery, Types.AllBonusBehaviorSubmissionPayloadsQueryVariables>;
export const AllBonusBehaviorsDocument = gql`
    query allBonusBehaviors($memberId: UUID!) {
  allBonusBehaviors(memberId: $memberId) {
    status
    icon
    title
    startDate
    endDate
    submittedDate
    completedDate
    rewards {
      amount
      currencySymbol
    }
  }
}
    `;

/**
 * __useAllBonusBehaviorsQuery__
 *
 * To run a query within a React component, call `useAllBonusBehaviorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBonusBehaviorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBonusBehaviorsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useAllBonusBehaviorsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.AllBonusBehaviorsQuery, Types.AllBonusBehaviorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllBonusBehaviorsQuery, Types.AllBonusBehaviorsQueryVariables>(AllBonusBehaviorsDocument, options);
      }
export function useAllBonusBehaviorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllBonusBehaviorsQuery, Types.AllBonusBehaviorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllBonusBehaviorsQuery, Types.AllBonusBehaviorsQueryVariables>(AllBonusBehaviorsDocument, options);
        }
export type AllBonusBehaviorsQueryHookResult = ReturnType<typeof useAllBonusBehaviorsQuery>;
export type AllBonusBehaviorsLazyQueryHookResult = ReturnType<typeof useAllBonusBehaviorsLazyQuery>;
export type AllBonusBehaviorsQueryResult = ApolloReactCommon.QueryResult<Types.AllBonusBehaviorsQuery, Types.AllBonusBehaviorsQueryVariables>;
export const AllBonusBehaviorConfigsDocument = gql`
    query allBonusBehaviorConfigs {
  allBonusBehaviorConfigs {
    id
    name
    programCode
    endDate
    startDate
    rewardAmount
    rewardCurrency
    note
    bonusBehaviorPagesetId
    icon
  }
}
    `;

/**
 * __useAllBonusBehaviorConfigsQuery__
 *
 * To run a query within a React component, call `useAllBonusBehaviorConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBonusBehaviorConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBonusBehaviorConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBonusBehaviorConfigsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllBonusBehaviorConfigsQuery, Types.AllBonusBehaviorConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllBonusBehaviorConfigsQuery, Types.AllBonusBehaviorConfigsQueryVariables>(AllBonusBehaviorConfigsDocument, options);
      }
export function useAllBonusBehaviorConfigsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllBonusBehaviorConfigsQuery, Types.AllBonusBehaviorConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllBonusBehaviorConfigsQuery, Types.AllBonusBehaviorConfigsQueryVariables>(AllBonusBehaviorConfigsDocument, options);
        }
export type AllBonusBehaviorConfigsQueryHookResult = ReturnType<typeof useAllBonusBehaviorConfigsQuery>;
export type AllBonusBehaviorConfigsLazyQueryHookResult = ReturnType<typeof useAllBonusBehaviorConfigsLazyQuery>;
export type AllBonusBehaviorConfigsQueryResult = ApolloReactCommon.QueryResult<Types.AllBonusBehaviorConfigsQuery, Types.AllBonusBehaviorConfigsQueryVariables>;
export const AllCustomersDocument = gql`
    query allCustomers($orderBy: [CustomersOrderBy!]) {
  allCustomers(orderBy: $orderBy) {
    nodes {
      name
      id
    }
  }
}
    `;

/**
 * __useAllCustomersQuery__
 *
 * To run a query within a React component, call `useAllCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllCustomersQuery, Types.AllCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllCustomersQuery, Types.AllCustomersQueryVariables>(AllCustomersDocument, options);
      }
export function useAllCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllCustomersQuery, Types.AllCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllCustomersQuery, Types.AllCustomersQueryVariables>(AllCustomersDocument, options);
        }
export type AllCustomersQueryHookResult = ReturnType<typeof useAllCustomersQuery>;
export type AllCustomersLazyQueryHookResult = ReturnType<typeof useAllCustomersLazyQuery>;
export type AllCustomersQueryResult = ApolloReactCommon.QueryResult<Types.AllCustomersQuery, Types.AllCustomersQueryVariables>;
export const AllDebitCardsDocument = gql`
    query allDebitCards($memberId: UUID!) {
  allDebitCards(condition: {patientId: $memberId}) {
    nodes {
      processor
      statusDesc
    }
  }
}
    `;

/**
 * __useAllDebitCardsQuery__
 *
 * To run a query within a React component, call `useAllDebitCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDebitCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDebitCardsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useAllDebitCardsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.AllDebitCardsQuery, Types.AllDebitCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllDebitCardsQuery, Types.AllDebitCardsQueryVariables>(AllDebitCardsDocument, options);
      }
export function useAllDebitCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllDebitCardsQuery, Types.AllDebitCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllDebitCardsQuery, Types.AllDebitCardsQueryVariables>(AllDebitCardsDocument, options);
        }
export type AllDebitCardsQueryHookResult = ReturnType<typeof useAllDebitCardsQuery>;
export type AllDebitCardsLazyQueryHookResult = ReturnType<typeof useAllDebitCardsLazyQuery>;
export type AllDebitCardsQueryResult = ApolloReactCommon.QueryResult<Types.AllDebitCardsQuery, Types.AllDebitCardsQueryVariables>;
export const AllMembersDocument = gql`
    query AllMembers($first: Int, $offset: Int, $after: Cursor, $orderBy: [MemberOrderBy!], $condition: MemberCondition) {
  allMembers(first: $first, offset: $offset, after: $after, orderBy: $orderBy, condition: $condition) @connection(key: "allMembers", filter: ["condition"]) {
    nodes {
      id
      createdAt
      siteName
      externalId
      pauseStatus
      program {
        programCode
      }
      status {
        value
        createdAt
        statusTransitionDate
        reason
      }
      person {
        shortId
        primaryFirstName
        primaryLastName
      }
      statuses {
        statusTransitionDate
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    `;

/**
 * __useAllMembersQuery__
 *
 * To run a query within a React component, call `useAllMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMembersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useAllMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllMembersQuery, Types.AllMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllMembersQuery, Types.AllMembersQueryVariables>(AllMembersDocument, options);
      }
export function useAllMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllMembersQuery, Types.AllMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllMembersQuery, Types.AllMembersQueryVariables>(AllMembersDocument, options);
        }
export type AllMembersQueryHookResult = ReturnType<typeof useAllMembersQuery>;
export type AllMembersLazyQueryHookResult = ReturnType<typeof useAllMembersLazyQuery>;
export type AllMembersQueryResult = ApolloReactCommon.QueryResult<Types.AllMembersQuery, Types.AllMembersQueryVariables>;
export const AllProgramTemplatesDocument = gql`
    query allProgramTemplates {
  allProgramTemplates {
    nodes {
      name
    }
  }
}
    `;

/**
 * __useAllProgramTemplatesQuery__
 *
 * To run a query within a React component, call `useAllProgramTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProgramTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProgramTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProgramTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllProgramTemplatesQuery, Types.AllProgramTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllProgramTemplatesQuery, Types.AllProgramTemplatesQueryVariables>(AllProgramTemplatesDocument, options);
      }
export function useAllProgramTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllProgramTemplatesQuery, Types.AllProgramTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllProgramTemplatesQuery, Types.AllProgramTemplatesQueryVariables>(AllProgramTemplatesDocument, options);
        }
export type AllProgramTemplatesQueryHookResult = ReturnType<typeof useAllProgramTemplatesQuery>;
export type AllProgramTemplatesLazyQueryHookResult = ReturnType<typeof useAllProgramTemplatesLazyQuery>;
export type AllProgramTemplatesQueryResult = ApolloReactCommon.QueryResult<Types.AllProgramTemplatesQuery, Types.AllProgramTemplatesQueryVariables>;
export const AllProgramsDocument = gql`
    query AllPrograms {
  allPrograms(orderBy: PROGRAM_CODE_ASC) {
    nodes {
      id
      programCode
      name
      sector
      customerId
      customerByCustomerId {
        name
      }
      createdAt
      expiry
      totalActiveMembers
      currentActiveMembers
      inactivateAfterPausedDays
      pauseAfterNoActivityDays
      rewardCardInfoUrl
      programTemplate
      isProspectReopeningSuppressed
      isProspectCreationSuppressed
      isTestProgram
      isUploaderDisabled
    }
  }
}
    `;

/**
 * __useAllProgramsQuery__
 *
 * To run a query within a React component, call `useAllProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProgramsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllProgramsQuery, Types.AllProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllProgramsQuery, Types.AllProgramsQueryVariables>(AllProgramsDocument, options);
      }
export function useAllProgramsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllProgramsQuery, Types.AllProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllProgramsQuery, Types.AllProgramsQueryVariables>(AllProgramsDocument, options);
        }
export type AllProgramsQueryHookResult = ReturnType<typeof useAllProgramsQuery>;
export type AllProgramsLazyQueryHookResult = ReturnType<typeof useAllProgramsLazyQuery>;
export type AllProgramsQueryResult = ApolloReactCommon.QueryResult<Types.AllProgramsQuery, Types.AllProgramsQueryVariables>;
export const AllSmsOutreachMessageTemplatesDocument = gql`
    query AllSmsOutreachMessageTemplates {
  allSmsOutreachMessageTemplates(orderBy: [UPDATED_AT_DESC], condition: {isActive: true}) {
    nodes {
      id
      createdAt
      updatedAt
      adminId
      message
      title
    }
  }
}
    `;

/**
 * __useAllSmsOutreachMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useAllSmsOutreachMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSmsOutreachMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSmsOutreachMessageTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSmsOutreachMessageTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllSmsOutreachMessageTemplatesQuery, Types.AllSmsOutreachMessageTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllSmsOutreachMessageTemplatesQuery, Types.AllSmsOutreachMessageTemplatesQueryVariables>(AllSmsOutreachMessageTemplatesDocument, options);
      }
export function useAllSmsOutreachMessageTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllSmsOutreachMessageTemplatesQuery, Types.AllSmsOutreachMessageTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllSmsOutreachMessageTemplatesQuery, Types.AllSmsOutreachMessageTemplatesQueryVariables>(AllSmsOutreachMessageTemplatesDocument, options);
        }
export type AllSmsOutreachMessageTemplatesQueryHookResult = ReturnType<typeof useAllSmsOutreachMessageTemplatesQuery>;
export type AllSmsOutreachMessageTemplatesLazyQueryHookResult = ReturnType<typeof useAllSmsOutreachMessageTemplatesLazyQuery>;
export type AllSmsOutreachMessageTemplatesQueryResult = ApolloReactCommon.QueryResult<Types.AllSmsOutreachMessageTemplatesQuery, Types.AllSmsOutreachMessageTemplatesQueryVariables>;
export const AllSmsOutreachMessagesDocument = gql`
    query AllSmsOutreachMessages($first: Int, $offset: Int, $after: Cursor, $condition: SmsOutreachMessageCondition) {
  allSmsOutreachMessages(first: $first, offset: $offset, after: $after, condition: $condition, orderBy: CREATED_AT_ASC) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        message
        approveAt
        smsOutreachRecipientByRecipientId {
          id
          firstName
          lastName
          phoneNumber
          programByProgramId {
            programCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllSmsOutreachMessagesQuery__
 *
 * To run a query within a React component, call `useAllSmsOutreachMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSmsOutreachMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSmsOutreachMessagesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useAllSmsOutreachMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AllSmsOutreachMessagesQuery, Types.AllSmsOutreachMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AllSmsOutreachMessagesQuery, Types.AllSmsOutreachMessagesQueryVariables>(AllSmsOutreachMessagesDocument, options);
      }
export function useAllSmsOutreachMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AllSmsOutreachMessagesQuery, Types.AllSmsOutreachMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AllSmsOutreachMessagesQuery, Types.AllSmsOutreachMessagesQueryVariables>(AllSmsOutreachMessagesDocument, options);
        }
export type AllSmsOutreachMessagesQueryHookResult = ReturnType<typeof useAllSmsOutreachMessagesQuery>;
export type AllSmsOutreachMessagesLazyQueryHookResult = ReturnType<typeof useAllSmsOutreachMessagesLazyQuery>;
export type AllSmsOutreachMessagesQueryResult = ApolloReactCommon.QueryResult<Types.AllSmsOutreachMessagesQuery, Types.AllSmsOutreachMessagesQueryVariables>;
export const AvailableProgramsDocument = gql`
    query AvailablePrograms {
  availablePrograms {
    id
    programCode
    programTemplate
  }
}
    `;

/**
 * __useAvailableProgramsQuery__
 *
 * To run a query within a React component, call `useAvailableProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableProgramsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AvailableProgramsQuery, Types.AvailableProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.AvailableProgramsQuery, Types.AvailableProgramsQueryVariables>(AvailableProgramsDocument, options);
      }
export function useAvailableProgramsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AvailableProgramsQuery, Types.AvailableProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.AvailableProgramsQuery, Types.AvailableProgramsQueryVariables>(AvailableProgramsDocument, options);
        }
export type AvailableProgramsQueryHookResult = ReturnType<typeof useAvailableProgramsQuery>;
export type AvailableProgramsLazyQueryHookResult = ReturnType<typeof useAvailableProgramsLazyQuery>;
export type AvailableProgramsQueryResult = ApolloReactCommon.QueryResult<Types.AvailableProgramsQuery, Types.AvailableProgramsQueryVariables>;
export const CreatePersonResourceIdentityDocument = gql`
    query CreatePersonResourceIdentity($memberId: UUID!) {
  member(id: $memberId) {
    ...MemberCustomerIdentity
  }
}
    ${MemberCustomerIdentityFragmentDoc}`;

/**
 * __useCreatePersonResourceIdentityQuery__
 *
 * To run a query within a React component, call `useCreatePersonResourceIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonResourceIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatePersonResourceIdentityQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useCreatePersonResourceIdentityQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.CreatePersonResourceIdentityQuery, Types.CreatePersonResourceIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.CreatePersonResourceIdentityQuery, Types.CreatePersonResourceIdentityQueryVariables>(CreatePersonResourceIdentityDocument, options);
      }
export function useCreatePersonResourceIdentityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CreatePersonResourceIdentityQuery, Types.CreatePersonResourceIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.CreatePersonResourceIdentityQuery, Types.CreatePersonResourceIdentityQueryVariables>(CreatePersonResourceIdentityDocument, options);
        }
export type CreatePersonResourceIdentityQueryHookResult = ReturnType<typeof useCreatePersonResourceIdentityQuery>;
export type CreatePersonResourceIdentityLazyQueryHookResult = ReturnType<typeof useCreatePersonResourceIdentityLazyQuery>;
export type CreatePersonResourceIdentityQueryResult = ApolloReactCommon.QueryResult<Types.CreatePersonResourceIdentityQuery, Types.CreatePersonResourceIdentityQueryVariables>;
export const CurrentAdminDocument = gql`
    query CurrentAdmin {
  currentAdmin {
    id
    lastName
    firstName
    adminRoleByAdminRoleId {
      name
    }
  }
}
    `;

/**
 * __useCurrentAdminQuery__
 *
 * To run a query within a React component, call `useCurrentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAdminQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CurrentAdminQuery, Types.CurrentAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.CurrentAdminQuery, Types.CurrentAdminQueryVariables>(CurrentAdminDocument, options);
      }
export function useCurrentAdminLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CurrentAdminQuery, Types.CurrentAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.CurrentAdminQuery, Types.CurrentAdminQueryVariables>(CurrentAdminDocument, options);
        }
export type CurrentAdminQueryHookResult = ReturnType<typeof useCurrentAdminQuery>;
export type CurrentAdminLazyQueryHookResult = ReturnType<typeof useCurrentAdminLazyQuery>;
export type CurrentAdminQueryResult = ApolloReactCommon.QueryResult<Types.CurrentAdminQuery, Types.CurrentAdminQueryVariables>;
export const CurrentAdminIdDocument = gql`
    query CurrentAdminId {
  currentAdminId
}
    `;

/**
 * __useCurrentAdminIdQuery__
 *
 * To run a query within a React component, call `useCurrentAdminIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAdminIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAdminIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAdminIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CurrentAdminIdQuery, Types.CurrentAdminIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.CurrentAdminIdQuery, Types.CurrentAdminIdQueryVariables>(CurrentAdminIdDocument, options);
      }
export function useCurrentAdminIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CurrentAdminIdQuery, Types.CurrentAdminIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.CurrentAdminIdQuery, Types.CurrentAdminIdQueryVariables>(CurrentAdminIdDocument, options);
        }
export type CurrentAdminIdQueryHookResult = ReturnType<typeof useCurrentAdminIdQuery>;
export type CurrentAdminIdLazyQueryHookResult = ReturnType<typeof useCurrentAdminIdLazyQuery>;
export type CurrentAdminIdQueryResult = ApolloReactCommon.QueryResult<Types.CurrentAdminIdQuery, Types.CurrentAdminIdQueryVariables>;
export const DeviceDetailsDocument = gql`
    query DeviceDetails($wellthId: UUID!) {
  deviceDetails(wellthId: $wellthId) {
    id
    wellthId
    lastSeen
    model
    appVersion
    os
    osVersion
    appBuildNumber
    brand
    cameraPermission
    notificationsPermission
    locationPermission
    carrier
    manufacturer
  }
}
    `;

/**
 * __useDeviceDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDetailsQuery({
 *   variables: {
 *      wellthId: // value for 'wellthId'
 *   },
 * });
 */
export function useDeviceDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.DeviceDetailsQuery, Types.DeviceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.DeviceDetailsQuery, Types.DeviceDetailsQueryVariables>(DeviceDetailsDocument, options);
      }
export function useDeviceDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DeviceDetailsQuery, Types.DeviceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.DeviceDetailsQuery, Types.DeviceDetailsQueryVariables>(DeviceDetailsDocument, options);
        }
export type DeviceDetailsQueryHookResult = ReturnType<typeof useDeviceDetailsQuery>;
export type DeviceDetailsLazyQueryHookResult = ReturnType<typeof useDeviceDetailsLazyQuery>;
export type DeviceDetailsQueryResult = ApolloReactCommon.QueryResult<Types.DeviceDetailsQuery, Types.DeviceDetailsQueryVariables>;
export const EditEmailAddressDocument = gql`
    query EditEmailAddress($emailId: UUID!) {
  emailAddress(id: $emailId) {
    ...Email
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useEditEmailAddressQuery__
 *
 * To run a query within a React component, call `useEditEmailAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditEmailAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditEmailAddressQuery({
 *   variables: {
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useEditEmailAddressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.EditEmailAddressQuery, Types.EditEmailAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.EditEmailAddressQuery, Types.EditEmailAddressQueryVariables>(EditEmailAddressDocument, options);
      }
export function useEditEmailAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EditEmailAddressQuery, Types.EditEmailAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.EditEmailAddressQuery, Types.EditEmailAddressQueryVariables>(EditEmailAddressDocument, options);
        }
export type EditEmailAddressQueryHookResult = ReturnType<typeof useEditEmailAddressQuery>;
export type EditEmailAddressLazyQueryHookResult = ReturnType<typeof useEditEmailAddressLazyQuery>;
export type EditEmailAddressQueryResult = ApolloReactCommon.QueryResult<Types.EditEmailAddressQuery, Types.EditEmailAddressQueryVariables>;
export const EditMailingAddressDocument = gql`
    query EditMailingAddress($mailingAddressId: UUID!) {
  address(id: $mailingAddressId) {
    ...FullMailingAddress
  }
}
    ${FullMailingAddressFragmentDoc}`;

/**
 * __useEditMailingAddressQuery__
 *
 * To run a query within a React component, call `useEditMailingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMailingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMailingAddressQuery({
 *   variables: {
 *      mailingAddressId: // value for 'mailingAddressId'
 *   },
 * });
 */
export function useEditMailingAddressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.EditMailingAddressQuery, Types.EditMailingAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.EditMailingAddressQuery, Types.EditMailingAddressQueryVariables>(EditMailingAddressDocument, options);
      }
export function useEditMailingAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EditMailingAddressQuery, Types.EditMailingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.EditMailingAddressQuery, Types.EditMailingAddressQueryVariables>(EditMailingAddressDocument, options);
        }
export type EditMailingAddressQueryHookResult = ReturnType<typeof useEditMailingAddressQuery>;
export type EditMailingAddressLazyQueryHookResult = ReturnType<typeof useEditMailingAddressLazyQuery>;
export type EditMailingAddressQueryResult = ApolloReactCommon.QueryResult<Types.EditMailingAddressQuery, Types.EditMailingAddressQueryVariables>;
export const EditNameDocument = gql`
    query EditName($nameId: UUID!) {
  name(id: $nameId) {
    ...FullName
  }
}
    ${FullNameFragmentDoc}`;

/**
 * __useEditNameQuery__
 *
 * To run a query within a React component, call `useEditNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditNameQuery({
 *   variables: {
 *      nameId: // value for 'nameId'
 *   },
 * });
 */
export function useEditNameQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.EditNameQuery, Types.EditNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.EditNameQuery, Types.EditNameQueryVariables>(EditNameDocument, options);
      }
export function useEditNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EditNameQuery, Types.EditNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.EditNameQuery, Types.EditNameQueryVariables>(EditNameDocument, options);
        }
export type EditNameQueryHookResult = ReturnType<typeof useEditNameQuery>;
export type EditNameLazyQueryHookResult = ReturnType<typeof useEditNameLazyQuery>;
export type EditNameQueryResult = ApolloReactCommon.QueryResult<Types.EditNameQuery, Types.EditNameQueryVariables>;
export const EditPhoneNumberDocument = gql`
    query EditPhoneNumber($phoneNumberId: UUID!) {
  phoneNumber(id: $phoneNumberId) {
    ...FullPhoneNumber
  }
}
    ${FullPhoneNumberFragmentDoc}`;

/**
 * __useEditPhoneNumberQuery__
 *
 * To run a query within a React component, call `useEditPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPhoneNumberQuery({
 *   variables: {
 *      phoneNumberId: // value for 'phoneNumberId'
 *   },
 * });
 */
export function useEditPhoneNumberQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.EditPhoneNumberQuery, Types.EditPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.EditPhoneNumberQuery, Types.EditPhoneNumberQueryVariables>(EditPhoneNumberDocument, options);
      }
export function useEditPhoneNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EditPhoneNumberQuery, Types.EditPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.EditPhoneNumberQuery, Types.EditPhoneNumberQueryVariables>(EditPhoneNumberDocument, options);
        }
export type EditPhoneNumberQueryHookResult = ReturnType<typeof useEditPhoneNumberQuery>;
export type EditPhoneNumberLazyQueryHookResult = ReturnType<typeof useEditPhoneNumberLazyQuery>;
export type EditPhoneNumberQueryResult = ApolloReactCommon.QueryResult<Types.EditPhoneNumberQuery, Types.EditPhoneNumberQueryVariables>;
export const EmailAddressDocument = gql`
    query EmailAddress($id: UUID!) {
  emailAddress(id: $id) {
    ...Email
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useEmailAddressQuery__
 *
 * To run a query within a React component, call `useEmailAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailAddressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.EmailAddressQuery, Types.EmailAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.EmailAddressQuery, Types.EmailAddressQueryVariables>(EmailAddressDocument, options);
      }
export function useEmailAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EmailAddressQuery, Types.EmailAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.EmailAddressQuery, Types.EmailAddressQueryVariables>(EmailAddressDocument, options);
        }
export type EmailAddressQueryHookResult = ReturnType<typeof useEmailAddressQuery>;
export type EmailAddressLazyQueryHookResult = ReturnType<typeof useEmailAddressLazyQuery>;
export type EmailAddressQueryResult = ApolloReactCommon.QueryResult<Types.EmailAddressQuery, Types.EmailAddressQueryVariables>;
export const GetAllProspectJobDocument = gql`
    query GetAllProspectJob($limit: Int) {
  getAllProspectJobs(limit: $limit) {
    id
    createdAt
    status
    program {
      programCode
    }
  }
}
    `;

/**
 * __useGetAllProspectJobQuery__
 *
 * To run a query within a React component, call `useGetAllProspectJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProspectJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProspectJobQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllProspectJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GetAllProspectJobQuery, Types.GetAllProspectJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetAllProspectJobQuery, Types.GetAllProspectJobQueryVariables>(GetAllProspectJobDocument, options);
      }
export function useGetAllProspectJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetAllProspectJobQuery, Types.GetAllProspectJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetAllProspectJobQuery, Types.GetAllProspectJobQueryVariables>(GetAllProspectJobDocument, options);
        }
export type GetAllProspectJobQueryHookResult = ReturnType<typeof useGetAllProspectJobQuery>;
export type GetAllProspectJobLazyQueryHookResult = ReturnType<typeof useGetAllProspectJobLazyQuery>;
export type GetAllProspectJobQueryResult = ApolloReactCommon.QueryResult<Types.GetAllProspectJobQuery, Types.GetAllProspectJobQueryVariables>;
export const GetBonusBehaviorPagesByPagesetDocument = gql`
    query getBonusBehaviorPagesByPageset($id: UUID!) {
  getBonusBehaviorPagesByPageset(id: $id) {
    id
    title
    detail
    type
    icon
    bonusBehaviorPagesetId
  }
}
    `;

/**
 * __useGetBonusBehaviorPagesByPagesetQuery__
 *
 * To run a query within a React component, call `useGetBonusBehaviorPagesByPagesetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBonusBehaviorPagesByPagesetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBonusBehaviorPagesByPagesetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBonusBehaviorPagesByPagesetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetBonusBehaviorPagesByPagesetQuery, Types.GetBonusBehaviorPagesByPagesetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetBonusBehaviorPagesByPagesetQuery, Types.GetBonusBehaviorPagesByPagesetQueryVariables>(GetBonusBehaviorPagesByPagesetDocument, options);
      }
export function useGetBonusBehaviorPagesByPagesetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetBonusBehaviorPagesByPagesetQuery, Types.GetBonusBehaviorPagesByPagesetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetBonusBehaviorPagesByPagesetQuery, Types.GetBonusBehaviorPagesByPagesetQueryVariables>(GetBonusBehaviorPagesByPagesetDocument, options);
        }
export type GetBonusBehaviorPagesByPagesetQueryHookResult = ReturnType<typeof useGetBonusBehaviorPagesByPagesetQuery>;
export type GetBonusBehaviorPagesByPagesetLazyQueryHookResult = ReturnType<typeof useGetBonusBehaviorPagesByPagesetLazyQuery>;
export type GetBonusBehaviorPagesByPagesetQueryResult = ApolloReactCommon.QueryResult<Types.GetBonusBehaviorPagesByPagesetQuery, Types.GetBonusBehaviorPagesByPagesetQueryVariables>;
export const GetConfigsForProgramCodeDocument = gql`
    query GetConfigsForProgramCode($programCode: String!) {
  getConfigsForProgram(programCode: $programCode) {
    reminderConfigs {
      ...FullReminderConfig
    }
    rewardPeriodConfigs {
      ...FullRewardPeriodConfig
    }
    paymentOptions {
      id
      name
    }
  }
}
    ${FullReminderConfigFragmentDoc}
${FullRewardPeriodConfigFragmentDoc}`;

/**
 * __useGetConfigsForProgramCodeQuery__
 *
 * To run a query within a React component, call `useGetConfigsForProgramCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigsForProgramCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigsForProgramCodeQuery({
 *   variables: {
 *      programCode: // value for 'programCode'
 *   },
 * });
 */
export function useGetConfigsForProgramCodeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetConfigsForProgramCodeQuery, Types.GetConfigsForProgramCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetConfigsForProgramCodeQuery, Types.GetConfigsForProgramCodeQueryVariables>(GetConfigsForProgramCodeDocument, options);
      }
export function useGetConfigsForProgramCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetConfigsForProgramCodeQuery, Types.GetConfigsForProgramCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetConfigsForProgramCodeQuery, Types.GetConfigsForProgramCodeQueryVariables>(GetConfigsForProgramCodeDocument, options);
        }
export type GetConfigsForProgramCodeQueryHookResult = ReturnType<typeof useGetConfigsForProgramCodeQuery>;
export type GetConfigsForProgramCodeLazyQueryHookResult = ReturnType<typeof useGetConfigsForProgramCodeLazyQuery>;
export type GetConfigsForProgramCodeQueryResult = ApolloReactCommon.QueryResult<Types.GetConfigsForProgramCodeQuery, Types.GetConfigsForProgramCodeQueryVariables>;
export const MemberCalendarDocument = gql`
    query MemberCalendar($id: UUID!, $totalStartDate: Date!, $endDate: Date!) {
  member(id: $id) {
    id
    renderCalendarView(startDate: $totalStartDate, endDate: $endDate) {
      ...CalendarEvent
    }
  }
}
    ${CalendarEventFragmentDoc}`;

/**
 * __useMemberCalendarQuery__
 *
 * To run a query within a React component, call `useMemberCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *      totalStartDate: // value for 'totalStartDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useMemberCalendarQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberCalendarQuery, Types.MemberCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberCalendarQuery, Types.MemberCalendarQueryVariables>(MemberCalendarDocument, options);
      }
export function useMemberCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberCalendarQuery, Types.MemberCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberCalendarQuery, Types.MemberCalendarQueryVariables>(MemberCalendarDocument, options);
        }
export type MemberCalendarQueryHookResult = ReturnType<typeof useMemberCalendarQuery>;
export type MemberCalendarLazyQueryHookResult = ReturnType<typeof useMemberCalendarLazyQuery>;
export type MemberCalendarQueryResult = ApolloReactCommon.QueryResult<Types.MemberCalendarQuery, Types.MemberCalendarQueryVariables>;
export const MemberEmailAddressesDocument = gql`
    query MemberEmailAddresses($id: UUID!) {
  member(id: $id) {
    id
    person {
      id
      emailAddresses {
        ...Email
      }
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useMemberEmailAddressesQuery__
 *
 * To run a query within a React component, call `useMemberEmailAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberEmailAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberEmailAddressesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberEmailAddressesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberEmailAddressesQuery, Types.MemberEmailAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberEmailAddressesQuery, Types.MemberEmailAddressesQueryVariables>(MemberEmailAddressesDocument, options);
      }
export function useMemberEmailAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberEmailAddressesQuery, Types.MemberEmailAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberEmailAddressesQuery, Types.MemberEmailAddressesQueryVariables>(MemberEmailAddressesDocument, options);
        }
export type MemberEmailAddressesQueryHookResult = ReturnType<typeof useMemberEmailAddressesQuery>;
export type MemberEmailAddressesLazyQueryHookResult = ReturnType<typeof useMemberEmailAddressesLazyQuery>;
export type MemberEmailAddressesQueryResult = ApolloReactCommon.QueryResult<Types.MemberEmailAddressesQuery, Types.MemberEmailAddressesQueryVariables>;
export const MemberInformationDocument = gql`
    query MemberInformation($id: UUID!) {
  member(id: $id) {
    ...MemberCustomerIdentity
    ...MemberProfileInformation
  }
}
    ${MemberCustomerIdentityFragmentDoc}
${MemberProfileInformationFragmentDoc}`;

/**
 * __useMemberInformationQuery__
 *
 * To run a query within a React component, call `useMemberInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberInformationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberInformationQuery, Types.MemberInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberInformationQuery, Types.MemberInformationQueryVariables>(MemberInformationDocument, options);
      }
export function useMemberInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberInformationQuery, Types.MemberInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberInformationQuery, Types.MemberInformationQueryVariables>(MemberInformationDocument, options);
        }
export type MemberInformationQueryHookResult = ReturnType<typeof useMemberInformationQuery>;
export type MemberInformationLazyQueryHookResult = ReturnType<typeof useMemberInformationLazyQuery>;
export type MemberInformationQueryResult = ApolloReactCommon.QueryResult<Types.MemberInformationQuery, Types.MemberInformationQueryVariables>;
export const MemberMailingAddressesDocument = gql`
    query MemberMailingAddresses($id: UUID!) {
  member(id: $id) {
    id
    person {
      id
      addresses {
        ...FullMailingAddress
      }
    }
  }
}
    ${FullMailingAddressFragmentDoc}`;

/**
 * __useMemberMailingAddressesQuery__
 *
 * To run a query within a React component, call `useMemberMailingAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberMailingAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberMailingAddressesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberMailingAddressesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberMailingAddressesQuery, Types.MemberMailingAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberMailingAddressesQuery, Types.MemberMailingAddressesQueryVariables>(MemberMailingAddressesDocument, options);
      }
export function useMemberMailingAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberMailingAddressesQuery, Types.MemberMailingAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberMailingAddressesQuery, Types.MemberMailingAddressesQueryVariables>(MemberMailingAddressesDocument, options);
        }
export type MemberMailingAddressesQueryHookResult = ReturnType<typeof useMemberMailingAddressesQuery>;
export type MemberMailingAddressesLazyQueryHookResult = ReturnType<typeof useMemberMailingAddressesLazyQuery>;
export type MemberMailingAddressesQueryResult = ApolloReactCommon.QueryResult<Types.MemberMailingAddressesQuery, Types.MemberMailingAddressesQueryVariables>;
export const MemberMessagesDocument = gql`
    query MemberMessages($id: UUID!) {
  member(id: $id) {
    id
    memberMessages {
      id
      createdAt
      message
      admin {
        firstName
        lastName
      }
      recipient
      status
    }
  }
}
    `;

/**
 * __useMemberMessagesQuery__
 *
 * To run a query within a React component, call `useMemberMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberMessagesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberMessagesQuery, Types.MemberMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberMessagesQuery, Types.MemberMessagesQueryVariables>(MemberMessagesDocument, options);
      }
export function useMemberMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberMessagesQuery, Types.MemberMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberMessagesQuery, Types.MemberMessagesQueryVariables>(MemberMessagesDocument, options);
        }
export type MemberMessagesQueryHookResult = ReturnType<typeof useMemberMessagesQuery>;
export type MemberMessagesLazyQueryHookResult = ReturnType<typeof useMemberMessagesLazyQuery>;
export type MemberMessagesQueryResult = ApolloReactCommon.QueryResult<Types.MemberMessagesQuery, Types.MemberMessagesQueryVariables>;
export const MemberNamesDocument = gql`
    query MemberNames($id: UUID!) {
  member(id: $id) {
    id
    person {
      id
      names {
        ...FullName
      }
    }
  }
}
    ${FullNameFragmentDoc}`;

/**
 * __useMemberNamesQuery__
 *
 * To run a query within a React component, call `useMemberNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberNamesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberNamesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberNamesQuery, Types.MemberNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberNamesQuery, Types.MemberNamesQueryVariables>(MemberNamesDocument, options);
      }
export function useMemberNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberNamesQuery, Types.MemberNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberNamesQuery, Types.MemberNamesQueryVariables>(MemberNamesDocument, options);
        }
export type MemberNamesQueryHookResult = ReturnType<typeof useMemberNamesQuery>;
export type MemberNamesLazyQueryHookResult = ReturnType<typeof useMemberNamesLazyQuery>;
export type MemberNamesQueryResult = ApolloReactCommon.QueryResult<Types.MemberNamesQuery, Types.MemberNamesQueryVariables>;
export const MemberNotesDocument = gql`
    query MemberNotes($id: UUID!) {
  member(id: $id) {
    id
    memberNotes {
      id
      createdAt
      note
      admin {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useMemberNotesQuery__
 *
 * To run a query within a React component, call `useMemberNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberNotesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberNotesQuery, Types.MemberNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberNotesQuery, Types.MemberNotesQueryVariables>(MemberNotesDocument, options);
      }
export function useMemberNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberNotesQuery, Types.MemberNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberNotesQuery, Types.MemberNotesQueryVariables>(MemberNotesDocument, options);
        }
export type MemberNotesQueryHookResult = ReturnType<typeof useMemberNotesQuery>;
export type MemberNotesLazyQueryHookResult = ReturnType<typeof useMemberNotesLazyQuery>;
export type MemberNotesQueryResult = ApolloReactCommon.QueryResult<Types.MemberNotesQuery, Types.MemberNotesQueryVariables>;
export const MemberPauseStatusDocument = gql`
    query MemberPauseStatus($id: UUID!) {
  member(id: $id) {
    id
    pauseStatus
  }
}
    `;

/**
 * __useMemberPauseStatusQuery__
 *
 * To run a query within a React component, call `useMemberPauseStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPauseStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPauseStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberPauseStatusQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberPauseStatusQuery, Types.MemberPauseStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberPauseStatusQuery, Types.MemberPauseStatusQueryVariables>(MemberPauseStatusDocument, options);
      }
export function useMemberPauseStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberPauseStatusQuery, Types.MemberPauseStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberPauseStatusQuery, Types.MemberPauseStatusQueryVariables>(MemberPauseStatusDocument, options);
        }
export type MemberPauseStatusQueryHookResult = ReturnType<typeof useMemberPauseStatusQuery>;
export type MemberPauseStatusLazyQueryHookResult = ReturnType<typeof useMemberPauseStatusLazyQuery>;
export type MemberPauseStatusQueryResult = ApolloReactCommon.QueryResult<Types.MemberPauseStatusQuery, Types.MemberPauseStatusQueryVariables>;
export const MemberPersonProfileDocument = gql`
    query MemberPersonProfile($id: UUID!) {
  member(id: $id) {
    id
    externalId
    siteName
    eligibilityEndDate
    medicaidId
    wellthId
    latestTimezone {
      timezoneName
    }
    person {
      ...PersonDemographics
    }
    program {
      programTemplate
    }
  }
}
    ${PersonDemographicsFragmentDoc}`;

/**
 * __useMemberPersonProfileQuery__
 *
 * To run a query within a React component, call `useMemberPersonProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPersonProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPersonProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberPersonProfileQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberPersonProfileQuery, Types.MemberPersonProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberPersonProfileQuery, Types.MemberPersonProfileQueryVariables>(MemberPersonProfileDocument, options);
      }
export function useMemberPersonProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberPersonProfileQuery, Types.MemberPersonProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberPersonProfileQuery, Types.MemberPersonProfileQueryVariables>(MemberPersonProfileDocument, options);
        }
export type MemberPersonProfileQueryHookResult = ReturnType<typeof useMemberPersonProfileQuery>;
export type MemberPersonProfileLazyQueryHookResult = ReturnType<typeof useMemberPersonProfileLazyQuery>;
export type MemberPersonProfileQueryResult = ApolloReactCommon.QueryResult<Types.MemberPersonProfileQuery, Types.MemberPersonProfileQueryVariables>;
export const MemberPhoneNumbersDocument = gql`
    query MemberPhoneNumbers($id: UUID!) {
  member(id: $id) {
    id
    person {
      id
      phoneNumbers {
        ...FullPhoneNumber
      }
    }
  }
}
    ${FullPhoneNumberFragmentDoc}`;

/**
 * __useMemberPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useMemberPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPhoneNumbersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberPhoneNumbersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberPhoneNumbersQuery, Types.MemberPhoneNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberPhoneNumbersQuery, Types.MemberPhoneNumbersQueryVariables>(MemberPhoneNumbersDocument, options);
      }
export function useMemberPhoneNumbersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberPhoneNumbersQuery, Types.MemberPhoneNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberPhoneNumbersQuery, Types.MemberPhoneNumbersQueryVariables>(MemberPhoneNumbersDocument, options);
        }
export type MemberPhoneNumbersQueryHookResult = ReturnType<typeof useMemberPhoneNumbersQuery>;
export type MemberPhoneNumbersLazyQueryHookResult = ReturnType<typeof useMemberPhoneNumbersLazyQuery>;
export type MemberPhoneNumbersQueryResult = ApolloReactCommon.QueryResult<Types.MemberPhoneNumbersQuery, Types.MemberPhoneNumbersQueryVariables>;
export const MemberDocument = gql`
    query Member($id: UUID!, $last7StartDate: Date!, $today: Date!, $last30StartDate: Date!, $totalStartDate: Date!, $endDate: Date!) {
  member(id: $id) {
    id
    enrollmentDate
    onboardingDate
    activationDate
    wellthId
    externalId
    description
    enrolledBy
    typeOfEnrollment
    reminders {
      ...FullReminder
    }
    id
    createdAt
    updatedAt
    siteName
    status {
      value
    }
    latestTimezone {
      timezoneName
    }
    isTestMember
    isAutoPauseEnabled
    pauseStatus
    pauseDetails {
      id
      reason
      startDate
      endDate
      note
      totalPausedDays
      previousStartDate
      previousEndDate
      previousReason
    }
    isRestfulMember
    isReminderEditingOpen
    isReminderMessagingEnabled
    isLocalNotificationEnabled
    isProductTourRecurring
    eligibilityEndDate
    person {
      ...PersonProfileInformation
      shortId
    }
    program {
      programCode
      programTemplate
    }
    currentOrNextRewardPeriodBalance {
      rewardPeriodId
      periodSum
      periodReward
      periodCurrency
      periodEnd
      periodStart
      settleDate
    }
    last30: adherence(startDate: $last30StartDate, endDate: $today) {
      ...MemberProfileAdherence
    }
    last7: adherence(startDate: $last7StartDate, endDate: $today) {
      ...MemberProfileAdherence
    }
    total: adherence(startDate: $totalStartDate, endDate: $today) {
      ...MemberProfileAdherence
    }
    renderCalendarView(startDate: $totalStartDate, endDate: $endDate) {
      ...CalendarEvent
    }
    overallScore {
      scoreValue
      updatedAt
    }
    appointments {
      pageInfo {
        startCursor
        endCursor
      }
      nodes {
        ...FullAppointment
      }
    }
  }
}
    ${FullReminderFragmentDoc}
${PersonProfileInformationFragmentDoc}
${MemberProfileAdherenceFragmentDoc}
${CalendarEventFragmentDoc}
${FullAppointmentFragmentDoc}`;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *      last7StartDate: // value for 'last7StartDate'
 *      today: // value for 'today'
 *      last30StartDate: // value for 'last30StartDate'
 *      totalStartDate: // value for 'totalStartDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useMemberQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberQuery, Types.MemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberQuery, Types.MemberQueryVariables>(MemberDocument, options);
      }
export function useMemberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberQuery, Types.MemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberQuery, Types.MemberQueryVariables>(MemberDocument, options);
        }
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberQueryResult = ApolloReactCommon.QueryResult<Types.MemberQuery, Types.MemberQueryVariables>;
export const MemberProgramExperienceDocument = gql`
    query MemberProgramExperience($id: UUID!) {
  member(id: $id) {
    id
    isTestMember
    isReminderMessagingEnabled
    isAutoPauseEnabled
  }
}
    `;

/**
 * __useMemberProgramExperienceQuery__
 *
 * To run a query within a React component, call `useMemberProgramExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberProgramExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberProgramExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberProgramExperienceQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberProgramExperienceQuery, Types.MemberProgramExperienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberProgramExperienceQuery, Types.MemberProgramExperienceQueryVariables>(MemberProgramExperienceDocument, options);
      }
export function useMemberProgramExperienceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberProgramExperienceQuery, Types.MemberProgramExperienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberProgramExperienceQuery, Types.MemberProgramExperienceQueryVariables>(MemberProgramExperienceDocument, options);
        }
export type MemberProgramExperienceQueryHookResult = ReturnType<typeof useMemberProgramExperienceQuery>;
export type MemberProgramExperienceLazyQueryHookResult = ReturnType<typeof useMemberProgramExperienceLazyQuery>;
export type MemberProgramExperienceQueryResult = ApolloReactCommon.QueryResult<Types.MemberProgramExperienceQuery, Types.MemberProgramExperienceQueryVariables>;
export const MemberRemindersDocument = gql`
    query MemberReminders($id: UUID!) {
  member(id: $id) {
    id
    reminders {
      ...FullReminder
    }
  }
}
    ${FullReminderFragmentDoc}`;

/**
 * __useMemberRemindersQuery__
 *
 * To run a query within a React component, call `useMemberRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberRemindersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberRemindersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberRemindersQuery, Types.MemberRemindersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberRemindersQuery, Types.MemberRemindersQueryVariables>(MemberRemindersDocument, options);
      }
export function useMemberRemindersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberRemindersQuery, Types.MemberRemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberRemindersQuery, Types.MemberRemindersQueryVariables>(MemberRemindersDocument, options);
        }
export type MemberRemindersQueryHookResult = ReturnType<typeof useMemberRemindersQuery>;
export type MemberRemindersLazyQueryHookResult = ReturnType<typeof useMemberRemindersLazyQuery>;
export type MemberRemindersQueryResult = ApolloReactCommon.QueryResult<Types.MemberRemindersQuery, Types.MemberRemindersQueryVariables>;
export const MemberTimelineDocument = gql`
    query MemberTimeline($wellthId: UUID, $excludeEventCriteria: [String], $startTimestamp: String) {
  memberTimelineEvents(wellthId: $wellthId, excludeEventCriteria: $excludeEventCriteria, startTimestamp: $startTimestamp) {
    id
    name
    timestamp
    properties
  }
}
    `;

/**
 * __useMemberTimelineQuery__
 *
 * To run a query within a React component, call `useMemberTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberTimelineQuery({
 *   variables: {
 *      wellthId: // value for 'wellthId'
 *      excludeEventCriteria: // value for 'excludeEventCriteria'
 *      startTimestamp: // value for 'startTimestamp'
 *   },
 * });
 */
export function useMemberTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MemberTimelineQuery, Types.MemberTimelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberTimelineQuery, Types.MemberTimelineQueryVariables>(MemberTimelineDocument, options);
      }
export function useMemberTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberTimelineQuery, Types.MemberTimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberTimelineQuery, Types.MemberTimelineQueryVariables>(MemberTimelineDocument, options);
        }
export type MemberTimelineQueryHookResult = ReturnType<typeof useMemberTimelineQuery>;
export type MemberTimelineLazyQueryHookResult = ReturnType<typeof useMemberTimelineLazyQuery>;
export type MemberTimelineQueryResult = ApolloReactCommon.QueryResult<Types.MemberTimelineQuery, Types.MemberTimelineQueryVariables>;
export const MemberMixpanelEventsDocument = gql`
    query MemberMixpanelEvents($id: UUID!, $today: Date!, $totalStartDate: Date!, $eventLimit: Int!) {
  member(id: $id) {
    id
    getMixpanelEvents(fromDate: $totalStartDate, toDate: $today, limit: $eventLimit) {
      events {
        name
        to
        from
        target
        model
        time
        type
        networkConnectionType
      }
    }
  }
}
    `;

/**
 * __useMemberMixpanelEventsQuery__
 *
 * To run a query within a React component, call `useMemberMixpanelEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberMixpanelEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberMixpanelEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      today: // value for 'today'
 *      totalStartDate: // value for 'totalStartDate'
 *      eventLimit: // value for 'eventLimit'
 *   },
 * });
 */
export function useMemberMixpanelEventsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberMixpanelEventsQuery, Types.MemberMixpanelEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberMixpanelEventsQuery, Types.MemberMixpanelEventsQueryVariables>(MemberMixpanelEventsDocument, options);
      }
export function useMemberMixpanelEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberMixpanelEventsQuery, Types.MemberMixpanelEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberMixpanelEventsQuery, Types.MemberMixpanelEventsQueryVariables>(MemberMixpanelEventsDocument, options);
        }
export type MemberMixpanelEventsQueryHookResult = ReturnType<typeof useMemberMixpanelEventsQuery>;
export type MemberMixpanelEventsLazyQueryHookResult = ReturnType<typeof useMemberMixpanelEventsLazyQuery>;
export type MemberMixpanelEventsQueryResult = ApolloReactCommon.QueryResult<Types.MemberMixpanelEventsQuery, Types.MemberMixpanelEventsQueryVariables>;
export const NameDocument = gql`
    query Name($id: UUID!) {
  name(id: $id) {
    ...FullName
  }
}
    ${FullNameFragmentDoc}`;

/**
 * __useNameQuery__
 *
 * To run a query within a React component, call `useNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNameQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.NameQuery, Types.NameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.NameQuery, Types.NameQueryVariables>(NameDocument, options);
      }
export function useNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.NameQuery, Types.NameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.NameQuery, Types.NameQueryVariables>(NameDocument, options);
        }
export type NameQueryHookResult = ReturnType<typeof useNameQuery>;
export type NameLazyQueryHookResult = ReturnType<typeof useNameLazyQuery>;
export type NameQueryResult = ApolloReactCommon.QueryResult<Types.NameQuery, Types.NameQueryVariables>;
export const MemberPaymentOptionDocument = gql`
    query MemberPaymentOption($condition: PaymentOptionCondition!) {
  memberPaymentOption(condition: $condition) {
    ...FullPaymentOption
  }
}
    ${FullPaymentOptionFragmentDoc}`;

/**
 * __useMemberPaymentOptionQuery__
 *
 * To run a query within a React component, call `useMemberPaymentOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPaymentOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPaymentOptionQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useMemberPaymentOptionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.MemberPaymentOptionQuery, Types.MemberPaymentOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MemberPaymentOptionQuery, Types.MemberPaymentOptionQueryVariables>(MemberPaymentOptionDocument, options);
      }
export function useMemberPaymentOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MemberPaymentOptionQuery, Types.MemberPaymentOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MemberPaymentOptionQuery, Types.MemberPaymentOptionQueryVariables>(MemberPaymentOptionDocument, options);
        }
export type MemberPaymentOptionQueryHookResult = ReturnType<typeof useMemberPaymentOptionQuery>;
export type MemberPaymentOptionLazyQueryHookResult = ReturnType<typeof useMemberPaymentOptionLazyQuery>;
export type MemberPaymentOptionQueryResult = ApolloReactCommon.QueryResult<Types.MemberPaymentOptionQuery, Types.MemberPaymentOptionQueryVariables>;
export const GetPcpInfoDocument = gql`
    query getPcpInfo($id: UUID!) {
  getProspectById(id: $id) {
    pcpName
    pcpNumber
  }
}
    `;

/**
 * __useGetPcpInfoQuery__
 *
 * To run a query within a React component, call `useGetPcpInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPcpInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPcpInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPcpInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetPcpInfoQuery, Types.GetPcpInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetPcpInfoQuery, Types.GetPcpInfoQueryVariables>(GetPcpInfoDocument, options);
      }
export function useGetPcpInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetPcpInfoQuery, Types.GetPcpInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetPcpInfoQuery, Types.GetPcpInfoQueryVariables>(GetPcpInfoDocument, options);
        }
export type GetPcpInfoQueryHookResult = ReturnType<typeof useGetPcpInfoQuery>;
export type GetPcpInfoLazyQueryHookResult = ReturnType<typeof useGetPcpInfoLazyQuery>;
export type GetPcpInfoQueryResult = ApolloReactCommon.QueryResult<Types.GetPcpInfoQuery, Types.GetPcpInfoQueryVariables>;
export const PersonProfileDocument = gql`
    query PersonProfile($id: UUID!) {
  member(id: $id) {
    id
    externalId
    siteName
    eligibilityEndDate
    person {
      ...PersonDemographics
    }
  }
}
    ${PersonDemographicsFragmentDoc}`;

/**
 * __usePersonProfileQuery__
 *
 * To run a query within a React component, call `usePersonProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonProfileQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.PersonProfileQuery, Types.PersonProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.PersonProfileQuery, Types.PersonProfileQueryVariables>(PersonProfileDocument, options);
      }
export function usePersonProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PersonProfileQuery, Types.PersonProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.PersonProfileQuery, Types.PersonProfileQueryVariables>(PersonProfileDocument, options);
        }
export type PersonProfileQueryHookResult = ReturnType<typeof usePersonProfileQuery>;
export type PersonProfileLazyQueryHookResult = ReturnType<typeof usePersonProfileLazyQuery>;
export type PersonProfileQueryResult = ApolloReactCommon.QueryResult<Types.PersonProfileQuery, Types.PersonProfileQueryVariables>;
export const PhoneNumberDocument = gql`
    query PhoneNumber($id: UUID!) {
  phoneNumber(id: $id) {
    ...FullPhoneNumber
  }
}
    ${FullPhoneNumberFragmentDoc}`;

/**
 * __usePhoneNumberQuery__
 *
 * To run a query within a React component, call `usePhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhoneNumberQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.PhoneNumberQuery, Types.PhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.PhoneNumberQuery, Types.PhoneNumberQueryVariables>(PhoneNumberDocument, options);
      }
export function usePhoneNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PhoneNumberQuery, Types.PhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.PhoneNumberQuery, Types.PhoneNumberQueryVariables>(PhoneNumberDocument, options);
        }
export type PhoneNumberQueryHookResult = ReturnType<typeof usePhoneNumberQuery>;
export type PhoneNumberLazyQueryHookResult = ReturnType<typeof usePhoneNumberLazyQuery>;
export type PhoneNumberQueryResult = ApolloReactCommon.QueryResult<Types.PhoneNumberQuery, Types.PhoneNumberQueryVariables>;
export const ProgramByIdDocument = gql`
    query ProgramById($id: UUID!) {
  programById(id: $id) {
    ...FullProgram
  }
}
    ${FullProgramFragmentDoc}`;

/**
 * __useProgramByIdQuery__
 *
 * To run a query within a React component, call `useProgramByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProgramByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ProgramByIdQuery, Types.ProgramByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProgramByIdQuery, Types.ProgramByIdQueryVariables>(ProgramByIdDocument, options);
      }
export function useProgramByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProgramByIdQuery, Types.ProgramByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProgramByIdQuery, Types.ProgramByIdQueryVariables>(ProgramByIdDocument, options);
        }
export type ProgramByIdQueryHookResult = ReturnType<typeof useProgramByIdQuery>;
export type ProgramByIdLazyQueryHookResult = ReturnType<typeof useProgramByIdLazyQuery>;
export type ProgramByIdQueryResult = ApolloReactCommon.QueryResult<Types.ProgramByIdQuery, Types.ProgramByIdQueryVariables>;
export const ProgramPaymentOptionsDocument = gql`
    query ProgramPaymentOptions($condition: ProgramPaymentOptionsCondition!) {
  programPaymentOptions(condition: $condition) {
    ...FullPaymentOption
  }
}
    ${FullPaymentOptionFragmentDoc}`;

/**
 * __useProgramPaymentOptionsQuery__
 *
 * To run a query within a React component, call `useProgramPaymentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramPaymentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramPaymentOptionsQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useProgramPaymentOptionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ProgramPaymentOptionsQuery, Types.ProgramPaymentOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProgramPaymentOptionsQuery, Types.ProgramPaymentOptionsQueryVariables>(ProgramPaymentOptionsDocument, options);
      }
export function useProgramPaymentOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProgramPaymentOptionsQuery, Types.ProgramPaymentOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProgramPaymentOptionsQuery, Types.ProgramPaymentOptionsQueryVariables>(ProgramPaymentOptionsDocument, options);
        }
export type ProgramPaymentOptionsQueryHookResult = ReturnType<typeof useProgramPaymentOptionsQuery>;
export type ProgramPaymentOptionsLazyQueryHookResult = ReturnType<typeof useProgramPaymentOptionsLazyQuery>;
export type ProgramPaymentOptionsQueryResult = ApolloReactCommon.QueryResult<Types.ProgramPaymentOptionsQuery, Types.ProgramPaymentOptionsQueryVariables>;
export const GetProspectByIdDocument = gql`
    query GetProspectById($id: UUID!) {
  getProspectById(id: $id) {
    id
    createdAt
    status
    externalId
    siteName
    eligibilityEndDate
    description
    isTestProspect
    medicaidId
    pcpName
    pcpNumber
    person {
      shortId
      id
      primaryTimezone
      primaryLanguage
      birthDate
      gender
      race
      ethnicity
      medicareId
      coverageType
      planName
      addresses {
        id
        street
        street2
        city
        state
        zip
        country
        isPrimary
      }
      names {
        id
        firstName
        middleName
        lastName
        isPrimary
      }
      phoneNumbers {
        id
        phoneNumber
        type
        isPrimary
      }
      emailAddresses {
        id
        emailAddress
        isPrimary
      }
    }
    program {
      programCode
      expiry
      programTemplate
    }
    prospectStatuses {
      value
      reason
      reasonDetail
      note
      id
      statusTransitionDate
      adminFirstName
      adminLastName
    }
    prospectNotes {
      id
      createdAt
      note
      admin {
        firstName
        lastName
      }
    }
    overallScore {
      scoreValue
      updatedAt
    }
    activeIdentityTags {
      name
    }
  }
}
    `;

/**
 * __useGetProspectByIdQuery__
 *
 * To run a query within a React component, call `useGetProspectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProspectByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetProspectByIdQuery, Types.GetProspectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetProspectByIdQuery, Types.GetProspectByIdQueryVariables>(GetProspectByIdDocument, options);
      }
export function useGetProspectByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetProspectByIdQuery, Types.GetProspectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetProspectByIdQuery, Types.GetProspectByIdQueryVariables>(GetProspectByIdDocument, options);
        }
export type GetProspectByIdQueryHookResult = ReturnType<typeof useGetProspectByIdQuery>;
export type GetProspectByIdLazyQueryHookResult = ReturnType<typeof useGetProspectByIdLazyQuery>;
export type GetProspectByIdQueryResult = ApolloReactCommon.QueryResult<Types.GetProspectByIdQuery, Types.GetProspectByIdQueryVariables>;
export const ProspectEmailAddressesDocument = gql`
    query ProspectEmailAddresses($id: UUID!) {
  getProspectById(id: $id) {
    id
    person {
      id
      emailAddresses {
        ...Email
      }
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useProspectEmailAddressesQuery__
 *
 * To run a query within a React component, call `useProspectEmailAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectEmailAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectEmailAddressesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectEmailAddressesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ProspectEmailAddressesQuery, Types.ProspectEmailAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProspectEmailAddressesQuery, Types.ProspectEmailAddressesQueryVariables>(ProspectEmailAddressesDocument, options);
      }
export function useProspectEmailAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProspectEmailAddressesQuery, Types.ProspectEmailAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProspectEmailAddressesQuery, Types.ProspectEmailAddressesQueryVariables>(ProspectEmailAddressesDocument, options);
        }
export type ProspectEmailAddressesQueryHookResult = ReturnType<typeof useProspectEmailAddressesQuery>;
export type ProspectEmailAddressesLazyQueryHookResult = ReturnType<typeof useProspectEmailAddressesLazyQuery>;
export type ProspectEmailAddressesQueryResult = ApolloReactCommon.QueryResult<Types.ProspectEmailAddressesQuery, Types.ProspectEmailAddressesQueryVariables>;
export const ProspectMailingAddressesDocument = gql`
    query ProspectMailingAddresses($id: UUID!) {
  getProspectById(id: $id) {
    id
    person {
      id
      addresses {
        ...FullMailingAddress
      }
    }
  }
}
    ${FullMailingAddressFragmentDoc}`;

/**
 * __useProspectMailingAddressesQuery__
 *
 * To run a query within a React component, call `useProspectMailingAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectMailingAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectMailingAddressesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectMailingAddressesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ProspectMailingAddressesQuery, Types.ProspectMailingAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProspectMailingAddressesQuery, Types.ProspectMailingAddressesQueryVariables>(ProspectMailingAddressesDocument, options);
      }
export function useProspectMailingAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProspectMailingAddressesQuery, Types.ProspectMailingAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProspectMailingAddressesQuery, Types.ProspectMailingAddressesQueryVariables>(ProspectMailingAddressesDocument, options);
        }
export type ProspectMailingAddressesQueryHookResult = ReturnType<typeof useProspectMailingAddressesQuery>;
export type ProspectMailingAddressesLazyQueryHookResult = ReturnType<typeof useProspectMailingAddressesLazyQuery>;
export type ProspectMailingAddressesQueryResult = ApolloReactCommon.QueryResult<Types.ProspectMailingAddressesQuery, Types.ProspectMailingAddressesQueryVariables>;
export const ProspectNamesDocument = gql`
    query ProspectNames($id: UUID!) {
  getProspectById(id: $id) {
    id
    person {
      id
      names {
        ...FullName
      }
    }
  }
}
    ${FullNameFragmentDoc}`;

/**
 * __useProspectNamesQuery__
 *
 * To run a query within a React component, call `useProspectNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectNamesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectNamesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ProspectNamesQuery, Types.ProspectNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProspectNamesQuery, Types.ProspectNamesQueryVariables>(ProspectNamesDocument, options);
      }
export function useProspectNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProspectNamesQuery, Types.ProspectNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProspectNamesQuery, Types.ProspectNamesQueryVariables>(ProspectNamesDocument, options);
        }
export type ProspectNamesQueryHookResult = ReturnType<typeof useProspectNamesQuery>;
export type ProspectNamesLazyQueryHookResult = ReturnType<typeof useProspectNamesLazyQuery>;
export type ProspectNamesQueryResult = ApolloReactCommon.QueryResult<Types.ProspectNamesQuery, Types.ProspectNamesQueryVariables>;
export const ProspectPhoneNumbersDocument = gql`
    query ProspectPhoneNumbers($id: UUID!) {
  getProspectById(id: $id) {
    id
    person {
      id
      phoneNumbers {
        ...FullPhoneNumber
      }
    }
  }
}
    ${FullPhoneNumberFragmentDoc}`;

/**
 * __useProspectPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useProspectPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectPhoneNumbersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectPhoneNumbersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ProspectPhoneNumbersQuery, Types.ProspectPhoneNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProspectPhoneNumbersQuery, Types.ProspectPhoneNumbersQueryVariables>(ProspectPhoneNumbersDocument, options);
      }
export function useProspectPhoneNumbersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProspectPhoneNumbersQuery, Types.ProspectPhoneNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProspectPhoneNumbersQuery, Types.ProspectPhoneNumbersQueryVariables>(ProspectPhoneNumbersDocument, options);
        }
export type ProspectPhoneNumbersQueryHookResult = ReturnType<typeof useProspectPhoneNumbersQuery>;
export type ProspectPhoneNumbersLazyQueryHookResult = ReturnType<typeof useProspectPhoneNumbersLazyQuery>;
export type ProspectPhoneNumbersQueryResult = ApolloReactCommon.QueryResult<Types.ProspectPhoneNumbersQuery, Types.ProspectPhoneNumbersQueryVariables>;
export const ProspectProgramExperienceDocument = gql`
    query ProspectProgramExperience($id: UUID!) {
  getProspectById(id: $id) {
    id
    isTestProspect
  }
}
    `;

/**
 * __useProspectProgramExperienceQuery__
 *
 * To run a query within a React component, call `useProspectProgramExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectProgramExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectProgramExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectProgramExperienceQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ProspectProgramExperienceQuery, Types.ProspectProgramExperienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProspectProgramExperienceQuery, Types.ProspectProgramExperienceQueryVariables>(ProspectProgramExperienceDocument, options);
      }
export function useProspectProgramExperienceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProspectProgramExperienceQuery, Types.ProspectProgramExperienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProspectProgramExperienceQuery, Types.ProspectProgramExperienceQueryVariables>(ProspectProgramExperienceDocument, options);
        }
export type ProspectProgramExperienceQueryHookResult = ReturnType<typeof useProspectProgramExperienceQuery>;
export type ProspectProgramExperienceLazyQueryHookResult = ReturnType<typeof useProspectProgramExperienceLazyQuery>;
export type ProspectProgramExperienceQueryResult = ApolloReactCommon.QueryResult<Types.ProspectProgramExperienceQuery, Types.ProspectProgramExperienceQueryVariables>;
export const ReminderDocument = gql`
    query Reminder($id: UUID!) {
  reminderById(id: $id) {
    ...FullReminder
  }
}
    ${FullReminderFragmentDoc}`;

/**
 * __useReminderQuery__
 *
 * To run a query within a React component, call `useReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReminderQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ReminderQuery, Types.ReminderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ReminderQuery, Types.ReminderQueryVariables>(ReminderDocument, options);
      }
export function useReminderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ReminderQuery, Types.ReminderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ReminderQuery, Types.ReminderQueryVariables>(ReminderDocument, options);
        }
export type ReminderQueryHookResult = ReturnType<typeof useReminderQuery>;
export type ReminderLazyQueryHookResult = ReturnType<typeof useReminderLazyQuery>;
export type ReminderQueryResult = ApolloReactCommon.QueryResult<Types.ReminderQuery, Types.ReminderQueryVariables>;
export const RewardCardDetailsByMemberIdDocument = gql`
    query RewardCardDetailsByMemberId($id: UUID!) {
  rewardCardDetailsByMemberId(id: $id) {
    id
    createdAt
    updatedAt
    status
    latestBalance
    processor
    shippedDate
    packId
    orderDate
    activationDate
    rewardCardChoice
  }
}
    `;

/**
 * __useRewardCardDetailsByMemberIdQuery__
 *
 * To run a query within a React component, call `useRewardCardDetailsByMemberIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardCardDetailsByMemberIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardCardDetailsByMemberIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRewardCardDetailsByMemberIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.RewardCardDetailsByMemberIdQuery, Types.RewardCardDetailsByMemberIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.RewardCardDetailsByMemberIdQuery, Types.RewardCardDetailsByMemberIdQueryVariables>(RewardCardDetailsByMemberIdDocument, options);
      }
export function useRewardCardDetailsByMemberIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.RewardCardDetailsByMemberIdQuery, Types.RewardCardDetailsByMemberIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.RewardCardDetailsByMemberIdQuery, Types.RewardCardDetailsByMemberIdQueryVariables>(RewardCardDetailsByMemberIdDocument, options);
        }
export type RewardCardDetailsByMemberIdQueryHookResult = ReturnType<typeof useRewardCardDetailsByMemberIdQuery>;
export type RewardCardDetailsByMemberIdLazyQueryHookResult = ReturnType<typeof useRewardCardDetailsByMemberIdLazyQuery>;
export type RewardCardDetailsByMemberIdQueryResult = ApolloReactCommon.QueryResult<Types.RewardCardDetailsByMemberIdQuery, Types.RewardCardDetailsByMemberIdQueryVariables>;
export const RewardPeriodBalancesDocument = gql`
    query rewardPeriodBalances($memberId: UUID!, $relativeTo: RewardBalanceRelativeTo) {
  rewardPeriodBalances(condition: {memberId: $memberId, relativeTo: $relativeTo}) {
    nodes {
      rewardPeriodId
      periodStart
      periodEnd
      periodLossPerDayMax
      periodReward
      periodSum
      settleDate
      rewardsEarned
    }
  }
}
    `;

/**
 * __useRewardPeriodBalancesQuery__
 *
 * To run a query within a React component, call `useRewardPeriodBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardPeriodBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardPeriodBalancesQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      relativeTo: // value for 'relativeTo'
 *   },
 * });
 */
export function useRewardPeriodBalancesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.RewardPeriodBalancesQuery, Types.RewardPeriodBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.RewardPeriodBalancesQuery, Types.RewardPeriodBalancesQueryVariables>(RewardPeriodBalancesDocument, options);
      }
export function useRewardPeriodBalancesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.RewardPeriodBalancesQuery, Types.RewardPeriodBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.RewardPeriodBalancesQuery, Types.RewardPeriodBalancesQueryVariables>(RewardPeriodBalancesDocument, options);
        }
export type RewardPeriodBalancesQueryHookResult = ReturnType<typeof useRewardPeriodBalancesQuery>;
export type RewardPeriodBalancesLazyQueryHookResult = ReturnType<typeof useRewardPeriodBalancesLazyQuery>;
export type RewardPeriodBalancesQueryResult = ApolloReactCommon.QueryResult<Types.RewardPeriodBalancesQuery, Types.RewardPeriodBalancesQueryVariables>;
export const SearchDocument = gql`
    query Search($searchTerm: String!) {
  search(searchTerm: $searchTerm) {
    results {
      ...FullPersonSearchResult
    }
  }
}
    ${FullPersonSearchResultFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.SearchQuery, Types.SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.SearchQuery, Types.SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SearchQuery, Types.SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.SearchQuery, Types.SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<Types.SearchQuery, Types.SearchQueryVariables>;
export const SearchByValuesDocument = gql`
    query SearchByValues($input: SearchByValueInput!) {
  searchByValues(input: $input) {
    results {
      ...FullPersonSearchResult
    }
  }
}
    ${FullPersonSearchResultFragmentDoc}`;

/**
 * __useSearchByValuesQuery__
 *
 * To run a query within a React component, call `useSearchByValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchByValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchByValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchByValuesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.SearchByValuesQuery, Types.SearchByValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.SearchByValuesQuery, Types.SearchByValuesQueryVariables>(SearchByValuesDocument, options);
      }
export function useSearchByValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SearchByValuesQuery, Types.SearchByValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.SearchByValuesQuery, Types.SearchByValuesQueryVariables>(SearchByValuesDocument, options);
        }
export type SearchByValuesQueryHookResult = ReturnType<typeof useSearchByValuesQuery>;
export type SearchByValuesLazyQueryHookResult = ReturnType<typeof useSearchByValuesLazyQuery>;
export type SearchByValuesQueryResult = ApolloReactCommon.QueryResult<Types.SearchByValuesQuery, Types.SearchByValuesQueryVariables>;
export const ValidateAddressDocument = gql`
    query ValidateAddress($input: ValidateAddressInput!) {
  validateAddress(input: $input) {
    isValid
    note
    correctedAddress {
      street
      street2
      city
      state
      zip
    }
  }
}
    `;

/**
 * __useValidateAddressQuery__
 *
 * To run a query within a React component, call `useValidateAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAddressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateAddressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.ValidateAddressQuery, Types.ValidateAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ValidateAddressQuery, Types.ValidateAddressQueryVariables>(ValidateAddressDocument, options);
      }
export function useValidateAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ValidateAddressQuery, Types.ValidateAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ValidateAddressQuery, Types.ValidateAddressQueryVariables>(ValidateAddressDocument, options);
        }
export type ValidateAddressQueryHookResult = ReturnType<typeof useValidateAddressQuery>;
export type ValidateAddressLazyQueryHookResult = ReturnType<typeof useValidateAddressLazyQuery>;
export type ValidateAddressQueryResult = ApolloReactCommon.QueryResult<Types.ValidateAddressQuery, Types.ValidateAddressQueryVariables>;