import React, { useState } from "react";
import { Spin, message, Button, Modal } from "@wellth/web-ui";
import { Dropdown } from "@wellth/web-ui/lib/components/Form/components/Dropdown";
import ProspectTable from "components/ProspectTable";
import {
  useUploadProspectFileMutation,
  useAllProgramsQuery,
  useGetAllProspectJobQuery,
} from "hooks/graphql";
import Upload from "../../components/Upload";

const isProductionEnvironment = process.env.BUILD_TYPE === "production";

const TEST_ENV_STYLE: React.CSSProperties = {
  fontSize: "4rem",
  color: "red",
};

const CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const TABLE_CONTAINER_STYLES: React.CSSProperties = {
  marginLeft: "1rem",
  marginRight: "1rem",
  marginTop: "5rem",
  display: "flex",
  flexDirection: "column",
};

const ICON_STYLES: React.CSSProperties = {
  marginLeft: "auto",
  marginRight: "2rem",
  marginBottom: "1rem",
};

const UPLOAD_STYLES: React.CSSProperties = {
  width: "31.25rem",
};

interface Program {
  id: string;
  programCode: string;
  isUploaderDisabled: boolean;
}

const handleUpload = async (
  file: any,
  uploadProspectFile: any,
  programId: string,
  // eslint-disable-next-line @typescript-eslint/require-await
) => uploadProspectFile({ variables: { file, programId } });

const handleCustomRequest = ({
  file,
  onError,
  onSuccess,
  uploadProspectFile,
  setSpinning,
  programId,
  setSelectedProgramCode,
  setProgramConfirmed,
  setProgramId,
}) => {
  setSpinning(true);
  // This is needed because custom request can't be an async function
  // https://github.com/ant-design/ant-design/issues/10122
  handleUpload(file, uploadProspectFile, programId)
    .then((res) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      void message.success(`Successfully uploaded ${file.name}`);
      onSuccess(res);
    })
    .catch((err) => {
      const errorMessage = err.message ? err.message : "";
      void message.error(`Unable to upload file. ${errorMessage}`);
      onError(err);
    })
    .finally(() => {
      setProgramConfirmed(false);
      setSelectedProgramCode(false);
      setSpinning(false);
      setProgramId(null);
    });

  return {
    // TODO: figure out how to cancel pending mutations
    // currently not supported.
    // Needed to prevent crashing error when navigation to a new page
    // mid download
    // https://github.com/ant-design/ant-design/issues/10122
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abort() {},
  };
};

export default () => {
  const [spinning, setSpinning] = useState(false);
  const [programId, setProgramId] = useState(null);
  const [selectedProgramCode, setSelectedProgramCode] = useState(null);
  const [uploadProspectFile] = useUploadProspectFileMutation();
  const [programConfirmed, setProgramConfirmed] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const {
    data: { allPrograms: { nodes = [] } = {} } = {},
  } = useAllProgramsQuery();
  const {
    data: { getAllProspectJobs = [] } = {},
    refetch,
  } = useGetAllProspectJobQuery();

  return (
    <div>
      <div style={CONTAINER_STYLES}>
        {isProductionEnvironment ? null : (
          <div style={TEST_ENV_STYLE}>THIS IS A TEST ENVIRONMENT</div>
        )}
        <Dropdown
          options={nodes
            .filter(({ isUploaderDisabled }) => !isUploaderDisabled)
            .map(({ programCode, id }: Program) => ({
              title: programCode,
              value: id,
            }))}
          onChange={(value) => {
            const doesValueExist = !!value;
            const {
              programCode: programCodeSelected,
              id: selectedId,
            } = doesValueExist
              ? nodes.find(({ id }) => id === value) || {}
              : { id: null, programCode: null };
            setProgramConfirmed(false);
            setSelectedProgramCode(programCodeSelected);
            setIsConfirmationVisible(doesValueExist);
            setProgramId(selectedId);
          }}
          placeholder="Choose a program"
          style={UPLOAD_STYLES}
          allowClear
        />

        <Modal
          title="Confirm Selected Program"
          visible={isConfirmationVisible}
          onOk={() => {
            setProgramConfirmed(true);
            setIsConfirmationVisible(false);
          }}
          onCancel={() => {
            setProgramConfirmed(false);
            setIsConfirmationVisible(false);
            setProgramId(null);
          }}
        >
          Please confirm that <b>{selectedProgramCode}</b> is the correct
          program code
        </Modal>

        <div style={CONTAINER_STYLES}>
          <Spin spinning={spinning}>
            <div style={UPLOAD_STYLES}>
              <Upload
                accept=".csv"
                customRequest={({ file, onError, onSuccess }) =>
                  handleCustomRequest({
                    file,
                    onError,
                    onSuccess,
                    uploadProspectFile,
                    setSpinning,
                    programId,
                    setSelectedProgramCode,
                    setProgramConfirmed,
                    setProgramId,
                  })
                }
                disabled={!programConfirmed}
              />
            </div>
          </Spin>
        </div>
      </div>

      <div style={TABLE_CONTAINER_STYLES}>
        <Button style={ICON_STYLES} icon="reload" onClick={() => refetch()} />
        <div style={{ width: "100%" }}>
          <ProspectTable
            data={getAllProspectJobs.map(
              ({ program, createdAt, status, id }) => ({
                programCode: program ? program.programCode : "[UNKNOWN]",
                dateSubmitted: createdAt,
                status,
                downloadLink:
                  status === "complete"
                    ? `${process.env.REACT_APP_API_URI.replace(
                        "/graphql",
                        "",
                      )}/prospect/result?id=${id}`
                    : null,
              }),
            )}
          />
        </div>
      </div>
    </div>
  );
};
