import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, FormInputType, OptionProps } from "@wellth/web-ui";
import moment from "moment-timezone";

export interface Customer {
  id: string;
  name: string;
}

export interface ProgramFormProps {
  programName: string;
  customerName: string;
  programTemplateLoading: boolean;
  allProgramTemplates: OptionProps[];
  programTemplate: string;
  sector: string;
  inactivateAfterPausedDays: number;
  pauseAfterNoActivityDays: number;
  expiry: string;
  rewardCardInfoUrl: string;
  isProspectCreationSuppressed: boolean;
  isProspectReopeningSuppressed: boolean;
  isUploaderDisabled: boolean;
}

export interface EditProgramFormProps extends Partial<ProgramFormProps> {
  inputStyle?: React.CSSProperties;
}

const EditProgramForm: React.RefForwardingComponent<
  any,
  EditProgramFormProps
> = (
  {
    inputStyle = {
      height: "2.1875rem",
      width: "18.75rem",
    },
    programTemplateLoading,
    allProgramTemplates,
    programTemplate,
    ...formProps
  }: EditProgramFormProps,
  ref,
) => {
  const formRef = React.useRef(null);
  useImperativeHandle(ref, () => ({
    getProgramValues: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));
  const [
    programTemplateConfirmation,
    displayProgramTemplateConfirmation,
  ] = useState(false);
  const [programTemplateValue, setProgramTemplateValue] = useState(
    programTemplate,
  );

  return (
    <Form
      {...formProps}
      wrappedComponentRef={formRef}
      mapPropsToFields={(
        {
          programName,
          customerName,
          sector,
          inactivateAfterPausedDays,
          pauseAfterNoActivityDays,
          expiry,
          rewardCardInfoUrl,
          isProspectCreationSuppressed,
          isProspectReopeningSuppressed,
          isUploaderDisabled,
        },
        createFormField,
      ) => ({
        programName: createFormField({ value: programName }),
        customerName: createFormField({
          value: customerName,
        }),
        programTemplate: createFormField({
          value: programTemplateValue,
        }),
        sector: createFormField({
          value: sector,
        }),
        inactivateAfterPausedDays: createFormField({
          value: inactivateAfterPausedDays,
        }),
        pauseAfterNoActivityDays: createFormField({
          value: pauseAfterNoActivityDays,
        }),
        expiry: createFormField({
          value: moment(expiry),
        }),
        rewardCardInfoUrl: createFormField({ value: rewardCardInfoUrl }),
        isProspectCreationSuppressed: createFormField({
          value: isProspectCreationSuppressed,
        }),
        isProspectReopeningSuppressed: createFormField({
          value: isProspectReopeningSuppressed,
        }),
        isUploaderDisabled: createFormField({ value: isUploaderDisabled }),
      })}
      inputs={[
        {
          type: FormInputType.Text,
          title: "Program Name:",
          key: "programName",
          style: inputStyle,
          decoratorOptions: {
            rules: [
              { required: true, message: "Please provide a program name." },
            ],
          },
        },
        {
          type: FormInputType.Info,
          title: "Customer Name",
          key: "customerName",
          style: inputStyle,
        },
        {
          type: FormInputType.Dropdown,
          title: "Program Template",
          key: "programTemplate",
          options: allProgramTemplates,
          loading: programTemplateLoading,
          style: inputStyle,
          onChange: (value: string) => {
            displayProgramTemplateConfirmation(true);
            setProgramTemplateValue(value);
          },
          confirmation: {
            title:
              "Changing program template value will affect reporting, are you sure?",
            visible: programTemplateConfirmation,
            cancelButtonProps: { hidden: true },
            onConfirm: () => displayProgramTemplateConfirmation(false),
          },
        },
        {
          type: FormInputType.Text,
          title: "Sector:",
          key: "sector",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "Paused Days Until Inactivation:",
          key: "inactivateAfterPausedDays",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "Consecutive Missed Days Until Pause:",
          key: "pauseAfterNoActivityDays",
          style: inputStyle,
        },
        {
          type: FormInputType.Date,
          format: "LL",
          title: "Expiry Date",
          key: "expiry",
          style: inputStyle,
        },
        {
          type: FormInputType.Text,
          title: "Reward Card Info URL",
          key: "rewardCardInfoUrl",
          style: inputStyle,
        },
        {
          type: FormInputType.Switch,
          title: "Suppress Prospect Creation",
          key: "isProspectCreationSuppressed",
          decoratorOptions: { valuePropName: "checked" },
        },
        {
          type: FormInputType.Switch,
          title: "Suppress Prospect Reopening",
          key: "isProspectReopeningSuppressed",
          decoratorOptions: { valuePropName: "checked" },
        },
        {
          type: FormInputType.Switch,
          title: "Disable Uploader",
          key: "isUploaderDisabled",
          decoratorOptions: { valuePropName: "checked" },
        },
      ]}
      labelCol={{ sm: { span: 10 }, xs: { span: 8 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
      colValue={12}
    />
  );
};

export default forwardRef(EditProgramForm);
